import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { NewProject } from '@src/components/projects';
import { Protected } from '@src/components/decorators';

@Protected(true)
class NewProjectsPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Create New Projects | STARS Gateway</title>
        </Helmet>
        <NewProject />
      </React.Fragment>
    );
  }
}

export default NewProjectsPage;