import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from '@src/components/shared';
import { required } from '@src/utils/validators';

class Login extends Component {
  render() {
    const { t } = this.context;
    const { forgotPasswordAction } = this.props;

    return (
      <Fragment>
        <div className='row animated delay-400ms flipInX'>
          <div className='col-5'>
            <Field
              name='user'
              type='text'
              text={t('USERNAME')}
              className='filled'
              validate={[required]}
            />
          </div>
          <div className='col-5'>
            <Field
              name='pass'
              type='password'
              text={t('PASSWORD')}
              className='filled'
              validate={[required]}
            />
          </div>
          <div className='col-2'>
            <button type='submit' className='btn login mt-4'>
              {t('LOGIN')}
            </button>
          </div>
        </div>
        <div className='row animated delay-600ms flipInX'>
          <div className='col-12'>
            <button
              type='button'
              className='btn link mt-2'
              onClick={() => forgotPasswordAction()}
            >
              {t('FORGOT_PASSWORD')}?
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

Login.contextTypes = {
  t: PropTypes.func.isRequired
};

Login.propTypes = {
  forgotPasswordAction: PropTypes.func.isRequired
}

export default Login;
