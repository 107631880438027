import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { closePopup } from '@src/components/popup/actions';
import { removeCatalogue } from '@src/components/download';

import Confirm from '../components/confirm';

const mapStateToProps = state => {
  const {
    router: { params: routeParams },
    popup: { data }
  } = state;

  return {
    routeParams,
    data
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closePopup,
      removeCatalogue
    },
    dispatch
  );
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Confirm)
);
