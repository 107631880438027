import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED

} from './reset-password-actions';

const INITIAL_STATE = {
  resetDone: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetDone: false
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetDone: true
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetDone: false
      };
    default:
      return state;
  }
}

