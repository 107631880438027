import axios from 'axios';
import base64 from 'base-64';
import { toast } from 'react-toastify';
import api, { apiUrl } from '@src/config/api';

export const AUTHENTICATION_REQUEST = 'AUTHENTICATION_REQUEST';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const AUTHENTICATION_CHECK = 'AUTHENTICATION_CHECK';
export const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT';

function authenticationSaveData(data) {
  let expireTime = new Date().getTime();
  expireTime += data.expires_in * 1000;

  localStorage.setItem('access_token', data.access_token);
  localStorage.setItem('expire_time', JSON.stringify(expireTime));
  localStorage.setItem('refresh_token', data.refresh_token);
}

export const authenticate = (user, pass) => {
  const clientId = 'test';
  const secret = 'secret';

  return dispatch => {
    dispatch({
      type: AUTHENTICATION_REQUEST
    });
    // eslint-disable-next-line
    let bodyFormData = new FormData();
    bodyFormData.set('grant_type', 'password');
    bodyFormData.set('username', user);
    bodyFormData.set('password', pass);

    return axios
      .post(`${ apiUrl() }/oauth/token`, bodyFormData, {
        headers: {
          Accept: 'application/json',
          Authorization: `Basic ${ base64.encode(`${ clientId }:${ secret }`) }`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(response => {
        authenticationSaveData(response.data);
        return Promise.all([api('users/current')]);
      })
      .then(response => {
        dispatch({ type: AUTHENTICATION_SUCCESS, payload: response[0] });
      })
      .catch(e => {
        dispatch({ type: AUTHENTICATION_FAILED });
        if (e.response.data && e.response.data.error_description) {
          toast.error(e.response.data.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        } else {
          toast.error('An error occurred.', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        }
      });
  };
};

export function clearUserData() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('expire_time');
  localStorage.removeItem('refresh_token');
}

export function fRrefreshToken(autoLogin = true) {
  console.log('fRrefreshToken');
  const clientId = 'test';
  const secret = 'secret';
  const refreshToken = localStorage.getItem('refresh_token');
  const bodyFormData = new FormData();
  bodyFormData.set('grant_type', 'refresh_token');
  bodyFormData.set('refresh_token', refreshToken);

  localStorage.removeItem('access_token');

  return axios
    .post(`${ apiUrl() }/oauth/token`, bodyFormData, {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${ base64.encode(`${ clientId }:${ secret }`) }`
      }
    })

    .then(async payload => {
      if (autoLogin) {
        let expireTime = new Date().getTime();
        expireTime += payload.expires_in * 1000;

        if (payload.error === 'invalid_grant') {
          clearUserData();
        }

        localStorage.setItem('access_token', payload.access_token);
        localStorage.setItem('expire_time', JSON.stringify(expireTime));
        localStorage.setItem('refresh_token', payload.refresh_token);
      }

      return payload;
    })
    .catch(error => {
      clearUserData();
      toast.error('An error occurred.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    });
}

export function checkAuthentication() {
  return async dispatch => {
    const expireTime = localStorage.getItem('expire_time');

    if (expireTime !== null && expireTime < Date.now()) {
      await fRrefreshToken();
    }

    const accessToken = localStorage.getItem('access_token');
    let userData = {};

    if (accessToken !== null) {
      userData = await api('users/current').catch(e => false);
    }

    if (!userData) {
      clearUserData();
      return dispatch({
        type: AUTHENTICATION_CHECK,
        payload: {
          logged: false
        }
      });
    }

    return [
      dispatch({
        type: AUTHENTICATION_CHECK,
        payload: {
          ...userData,
          logged: accessToken !== null
        }
      })
    ];
  };
}

export function authenticationLogout() {
  localStorage.clear();

  return dispatch => [dispatch({ type: AUTHENTICATION_LOGOUT })];
}

export const resetPassword = username => {
  return dispatch => [
    axios.post(`${ apiUrl() }/api/users/${ username }/password/reset`).then(res => {
      toast.success("We've reset your password. Check your email.", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    })
  ];
};
