import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import newId from '@src/utils/newid';

class TMTextareaComponent extends Component {
  componentWillMount() {
    this.id = newId();
  }

  render() {
    const { input, meta, className } = this.props;
    let addonClass = '';
    if (input.value !== undefined && input.value !== '') {
      addonClass = 'filled';
    }

    return (
      <div className={meta.touched && meta.error ? 'error' : ''}>
        <div className={['tm-input', addonClass, className].join(' ')}>
          <textarea {...input} id={this.id} />
          <label htmlFor={this.id}>
            { this.props.text }
          </label>
          <span />
        </div>
        {meta.touched && meta.error && (
          <div>{meta.error}</div>
        )}
      </div>
    );
  }
}

TMTextareaComponent.propTypes = {
  text: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

const TMTextarea = props => <Field name={props.name} component={TMTextareaComponent} {...props} />;

TMTextarea.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TMTextarea;
