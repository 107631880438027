import React, {Component} from 'react';
import {Field} from 'redux-form';

class Upload extends Component {
  render() {
    const {
      type: omitType,
      renderer,
      onChange: fileFieldOnChange,
      ...fileFieldProps
    } = this.props;

    return (
      <Field
        {...fileFieldProps}
        component={field => {
          const {
            input,
            meta,
            ...fieldProps
          } = field;

          const {
            value,
            onChange,
            ...inputProps
          } = input;

          const handleChange = (e => {
            const file = e.target.files[0];
            onChange(file);
            fileFieldOnChange && fileFieldOnChange({[inputProps.name]: file});
          });

          const Renderer = renderer || (() => 'Upload file');

          return (
            <label>
              <Renderer meta={meta} value={value} onChange={handleChange}/>
              <input
                {...fieldProps}
                {...inputProps}
                type="file"
                onChange={handleChange}
                style={{display: 'none'}}
              />
            </label>
          );
        }}
      />
    );
  };
}

export default Upload;
