import bg1 from '@res/images/bg1.jpg';
import bg2 from '@res/images/bg2.jpg';
import bg3 from '@res/images/bg3.jpg';
import bg4 from '@res/images/bg4.jpg';
import bg5 from '@res/images/bg5.jpg';
import bg6 from '@res/images/bg6.jpg';

export const common = {
  backgrounds: [ bg1, bg2, bg3, bg4, bg5, bg6 ],
  languages: [
    {
      slug: 'en',
      name: 'English'
    },
    {
      slug: 'pl',
      name: 'Polish'
    }
  ],
  files: [
    {
      id: 1,
      name: 'Sample file name',
      type: 'pdf',
    },
    {
      id: 2,
      name: 'Sample file name sd fsdff dsfsd fd  dsfsdfd sf sdfsdfsdfsd fs',
      type: 'doc',
    },
    {
      id: 3,
      name: 'Sample file namesdf sdf fds f',
      type: 'xls',
    },
    {
      id: 4,
      name: 'Sample file name dfsfdfs ',
      type: 'ppt',
    },
    {
      id: 5,
      name: 'Sample file name sdfsd ',
      type: 'docx',
    },
    {
      id: 6,
      name: 'Sample file name sd ',
      type: 'csv',
    },
    {
      id: 7,
      name: 'Sample file name',
      type: 'xlsx',
    },
  ]

}