import React, { Component } from 'react';
import { Protected } from '@src/components/decorators';
import { Table } from '@src/components/shared';

const cookieEssential = [
  {
    description: 'Access token',
    duration: 'Until delete cache'
  },
  {
    description: 'Refresh token',
    duration: 'Until delete cache'
  },
  {
    description: 'Accept cookies information',
    duration: 'Two years'
  }
];

@Protected(false)
export default class CookiesPage extends Component {
  render() {
    return (
      <div id='cookies' className='textPage animated fadeIn'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div>
                <h2>Cookie Policy</h2>
                <p>
                  This Cookie Policy explains how cookies and similar
                  technologies (collectively, “Cookie(s)”) are used when you
                  visit our Site. A “Site” includes our website and emails owned
                  and operated by Stars Sp. z o.o. (the “Company”, “our”, or
                  “us”) as well as any other services that display this Cookie
                  Policy. This policy explains what these technologies are and
                  why they are used, as well as your right to control their use.
                </p>
                <p>
                  We may change this Cookie Policy at any time. Please take a
                  look at the “LAST REVISED” date at the top of this page to see
                  when this Cookie Policy was last revised. Any change in this
                  Cookie Policy will become effective when we make the revised
                  Cookie Policy available on or through the Site.
                </p>
                <p>
                  If you have any question, please contact us by email at&nbsp;
                  <a href='mailto:contact@stars.com.pl'>contact@stars.com.pl</a>
                  , or write to us at the following address:
                  <br />
                  TSTARS Sp. z o.o.
                  <br />
                  ul. Romera 10 lok. B3,
                  <br />
                  02-784 Warsaw, Poland,
                  <br />
                  attn.: Privacy Counsel.
                </p>
              </div>

              <div>
                <h3>What Is A Cookie?</h3>
                <p>
                  A cookie is a small text file (often including a unique
                  identifier), that is sent to a user’s browser from a website's
                  computers and stored on a user’s computer's hard drive or on a
                  tablet or mobile device (collectively, “Computer”). A Cookie
                  stores a small amount of data on your Computer about your
                  visit to the Site.
                </p>
                <p>
                  We may also use “web beacons” (also known as “clear GIFs” or
                  “pixel tags”) or similar technologies on our Site to enable us
                  to know whether you have visited a web page or received a
                  message. A web beacon is typically a one-pixel, transparent
                  image (although it can be a visible image as well), located on
                  a web page or in an email or other type of message, which is
                  retrieved from a remote website on the internet enabling the
                  verification of an individual's viewing or receipt of a web
                  page or message. A web beacon helps us to understand whether
                  you came to our website from an online advertisement displayed
                  on a third-party website, measure successes of email marketing
                  campaigns and improve our website performance. They may also
                  enable us to relate your viewing or receipt of a web page or
                  message to other information about you, including your
                  personal information. In many instances, web beacons rely on
                  cookies to function properly, and so declining cookies will
                  impair their functioning.
                </p>
                <p>
                  We place and access Cookies on your Computer; these Cookies
                  are known as “first-party Cookies.” Some Cookies are "session
                  Cookies," which means that they are temporary Cookies that are
                  only stored on your device while you are visiting our Site.
                  Other Cookies are "persistent Cookies," which means that they
                  are stored on your device for a period of time after you leave
                  our Site.
                </p>
                <p>
                  You can choose whether to accept Cookies by changing the
                  settings on your browser. However, if you disable this
                  function (or keep this function disabled, as set by default by
                  certain browsers), your experience on the Site may be
                  diminished and some features may not work as intended.
                </p>
              </div>
              <div>
                <h3>What Cookies Do We Use?</h3>
                <p>
                  Below we list the different types of Cookies that are used on
                  the Site that you are visiting.
                </p>
                <p>
                  To the extent any personal information is collected through
                  first-party Cookies, our Privacy Policy applies and
                  complements this Cookie Policy.
                </p>
                <h5>Essential Cookies</h5>
                <p>
                  Essential Cookies enable you to navigate the Site and to use
                  its services and features. Without these necessary Cookies,
                  the Site will not perform as smoothly for you as we would like
                  it to and we may not be able to provide the Site or certain
                  services or features.
                </p>
                <Table
                  data={cookieEssential}
                  datamap={['description', 'duration']}
                  name='cookie-essential'
                  header={['COOKIE_DESCRIPTION', 'COOKIE_DURATION']}
                />
                {/*
                <h5>Preference Cookies</h5>
                <p>
                  Preference Cookies collect information about your choices and
                  preferences, and allow us to remember language or other local
                  settings and customize the Site accordingly.
                </p>
                <Table
                  data={cookiePreference}
                  datamap={['description', 'duration', 'policy']}
                  name='cookie-preference'
                  header={[
                    'COOKIE_DESCRIPTION',
                    'COOKIE_DURATION',
                    'COOKIE_POLICY'
                  ]}
                />
                <h5>Analytics Cookies</h5>
                <p>
                  Analytics Cookies collect information about your use of the
                  Site, and enable us to improve the way it works. For example,
                  Analytics Cookies show us which are the most frequently
                  visited pages on the Site, help us record any difficulties you
                  have with the Site, and show us whether our advertising is
                  effective or not. Analytics Cookies allow us to see the
                  overall patterns of usage on the Site, rather than the usage
                  of a single person. We use information from Analytics Cookies
                  to analyze the Site traffic, but we do not examine this
                  information for individually identifying information.
                </p>
                <Table
                  data={cookieAnalytics}
                  datamap={['description', 'duration', 'policy']}
                  name='cookies-analytics'
                  header={[
                    'COOKIE_DESCRIPTION',
                    'COOKIE_DURATION',
                    'COOKIE_POLICY'
                  ]}
                />
                */}
              </div>
              <div>
                <h3>How Do I Manage Cookies?</h3>
                <p>
                  You can change your Cookie settings above by opting out of
                  certain or all Cookies.
                </p>
                <p>
                  You may refuse or accept Cookies from the Site or any other
                  website at any time by activating settings on your browser.
                  Most browsers automatically accept Cookies, but you can
                  usually modify your browser setting to decline Cookies if you
                  prefer. If you choose to decline Cookies, you may not be able
                  to sign in or use other interactive features of our Site that
                  depend on Cookies. Information about the procedure to follow
                  in order to enable or disable Cookies can be found at:
                </p>

                <ul>
                  <li>
                    <a
                      href='https://support.google.com/chrome/answer/95647?hl=en'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Chrome
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://support.apple.com/kb/PH19255?locale=en_US'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Safari
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://support.apple.com/en-us/HT201265'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Safari Mobile (iPhone and iPads)
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectlocale=en-US&redirectslug=delete-cookies-remove-info-websites-stored'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Firefox
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Microsoft Edge
                    </a>
                  </li>
                </ul>

                <p>
                  For more information about other commonly used browsers,
                  please refer to&nbsp;
                  <br />
                  <a
                    href='http://www.allaboutcookies.org/manage-cookies/'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    http://www.allaboutcookies.org/manage-cookies/
                  </a>
                  .
                </p>
                <p>
                  Please be aware that if Cookies are disabled, not all features
                  of the Site may operate as intended.
                </p>
                <p>
                  To opt-out of participating in Google Analytics data follow
                  the instructions:&nbsp;
                  <br />
                  <a
                    href='https://tools.google.com/dlpage/gaoptout'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    https://tools.google.com/dlpage/gaoptout
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}