import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { closePopup } from '@src/components/popup/actions';

import ConfirmDeactivation from '../components/confirm-deactivation';

const mapStateToProps = state => {
  const {
    popup: { data }
  } = state;

  return {
    data
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closePopup
    },
    dispatch
  );
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConfirmDeactivation)
);
