import React, { Component } from 'react';
import { map, includes } from 'lodash';
import PropTypes from 'prop-types';
import { Preloader } from '@src/components/shared';
import {
  Header as DownloadHeader,
  List as DownloadList
} from '@src/components/download';
import { CatalogueBoxes } from './shared/catalogue-boxes';
import { MoveCatalogue } from './shared/move-catalogue';

class Download extends Component {
  state = {
    wait: true,
    files: [],
    editMode: false,
    isCatalogueEditMode: [],
    editedCatalogueId: '',
    selectedCatalogueId: '',
    searchCatalogueQuery: ''
  };

  componentWillMount() {
    this.getMarketAllFiles();
    this.getSubCatalogues();
    this.getActiveCatalogue();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.download.files !== nextProps.download.files) {
      this.setState(
        {
          wait: true
        },
        () => {
          this.handleFilesRecognition(nextProps);
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      download: {
        catalogues,
        pageWithSubCatalogues,
        updateCatalogueNameSuccess,
        updateCatalogueDestinationError,
        updateCatalogueDestinationLoading
      },
      match: {
        params: { catalogue: catalogueId }
      }
    } = this.props;

    const {
      download: {
        catalogues: prevCatalogues,
        pageWithSubCatalogues: prevPageWithSubCatalogues,
        updateCatalogueNameSuccess: prevUpdateCatalogueNameSuccess,
        updateCatalogueDestinationLoading: prevUpdateCatalogueDestinationLoading
      },
      match: {
        params: { catalogue: prevCatalogueId }
      }
    } = prevProps;

    const {
      searchCatalogueQuery,
      selectedCatalogueId
    } = this.state;

    const {
      searchCatalogueQuery: prevSearchCatalogueQuery,
      selectedCatalogueId: prevSelectedCatalogueId
    } = prevState;

    if (catalogueId !== prevCatalogueId) {
      this.getMarketAllFiles();
      this.getSubCatalogues();
      this.getActiveCatalogue();
      this.resetCataloguesState();
    }

    if (!prevUpdateCatalogueNameSuccess && updateCatalogueNameSuccess) {
      this.getSubCatalogues();
      this.setEditModeState();
      this.setInitialCatalogueEditMode(catalogues.map(() => false));
    }

    if (
      (catalogues.length > 0 && prevCatalogues.length === 0) ||
      (catalogueId !== prevCatalogueId) ||
      (pageWithSubCatalogues !== prevPageWithSubCatalogues)
    ) {
      this.setInitialCatalogueEditMode(catalogues.map(() => false));
    }

    if (
      (searchCatalogueQuery && searchCatalogueQuery !== prevSearchCatalogueQuery) ||
      (!searchCatalogueQuery && prevSearchCatalogueQuery) ||
      (selectedCatalogueId && !prevSelectedCatalogueId)
    ) {
      this.possibleParentCatalogues();
    }

    if (!updateCatalogueDestinationError && !updateCatalogueDestinationLoading && prevUpdateCatalogueDestinationLoading) {
      this.getSubCatalogues();
      this.resetCataloguesState();
    }
  }

  setEditModeState = () => this.setState({ editMode: !this.state.editMode });

  setSelectedCatalogueId = (catalogue, checked) => {
    let selectedCatalogueId = '';

    if (checked) {
      selectedCatalogueId = catalogue.id;
    } else {
      selectedCatalogueId = '';
    }
    this.setState({
      selectedCatalogueId
    });

    return selectedCatalogueId;
  };

  setInitialCatalogueEditMode = stateValue => {
    this.setState({ isCatalogueEditMode: stateValue });
  }

  setCataloguesEditMode = (index, event) => {
    const {
      isCatalogueEditMode
    } = this.state;
    event.preventDefault();
    const editedValuesArray = isCatalogueEditMode.map(item => item);

    editedValuesArray[index] = !isCatalogueEditMode[index] || false;

    this.setState({
      isCatalogueEditMode: editedValuesArray
    });
  }

  getMarketAllFiles = () => {
    const {
      match: {
        params: { catalogue }
      },
      getFiles
    } = this.props;

    getFiles(catalogue);
  };

  getSubCatalogues = () => {
    const {
      match: {
        params: { catalogue }
      },
      getSubCatalogues
    } = this.props;
    getSubCatalogues(catalogue);
  };

  getActiveCatalogue = () => {
    const {
      match: {
        params: { catalogue: catalogueId }
      },
      getActiveCatalogue
    } = this.props;

    getActiveCatalogue(catalogueId);
  };

  getSearchCataloguesValue = () => {
    const {
      download: {
        parentCatalogues
      }
    } = this.props;

    const destinationCatalogues = map(parentCatalogues, cat => ({
      label: cat.name,
      value: cat.catalogueId
    }));

    return destinationCatalogues;
  };

  handleFilesRecognition = nextProps => {
    const { files } = nextProps.download;

    const newFiles = [];
    const years = [];

    map(files, market => {
      if (market && market.length) {
        map(market, file => {
          const year = new Date(file.uploadTime).getFullYear();
          newFiles.push(file);

          if (includes(years, year) === false) {
            years.push(year);
          }
        });
      }
    });

    this.setState({
      wait: false,
      files: newFiles,
      years
    });
  };

  possibleParentCatalogues = () => {
    const {
      getPossibleParentCatalogues,
      project: {
        id: projectId
      }
    } = this.props;
    const {
      selectedCatalogueId,
      searchCatalogueQuery
    } = this.state;
    const data = {
      catalogueId: selectedCatalogueId,
      projectId,
      query: searchCatalogueQuery
    };
    getPossibleParentCatalogues(data);
  };

  updateCatalogueDestination = () => {
    const {
      destinationCatalogueId,
      updateCatalogueDestination
    } = this.props;

    const { selectedCatalogueId } = this.state;

    updateCatalogueDestination(selectedCatalogueId, destinationCatalogueId);
  };

  resetCataloguesState = () => {
    this.setState({
      editMode: false,
      isCatalogueEditMode: [],
      editedCatalogueId: '',
      selectedCatalogueId: '',
      searchCatalogueQuery: ''
    });
  };

  render() {
    const {
      openPopup,
      download: {
        subCatalogues,
        pageWithSubCatalogues,
        subCataloguesLoading,
        updateCatalogueDestinationLoading,
        activeCatalogue
      },
      home: {
        id: userId,
        role: currentUserRole
      },
      updateCatalogueName,
      destinationCatalogueId,
      project
    } = this.props;
    const {
      wait,
      years,
      files,
      editMode,
      isCatalogueEditMode,
      editedCatalogueId,
      selectedCatalogueId
    } = this.state;

    const { t } = this.context;

    return (
      <div id='download'>
        <div className='container'>
          <div className='row gutter'>
            <div className='col-12'>
              <DownloadHeader
                download
                catalogueName={activeCatalogue ? activeCatalogue.name : ''}
                removeBtn={activeCatalogue && !activeCatalogue.main ? 'Remove catalogue' : false}
                remove={() => {
                    openPopup('Confirm', {
                      activeCatalogue
                    });
                  }}
                removeBtnAdditionalClass='btn--download-remove'
                catalogueActive={activeCatalogue}
              />
            </div>
            {wait || subCataloguesLoading ? (
              <Preloader text='Please wait. The files and catalogues are loading...' />
              ) : (
                <React.Fragment>
                  {activeCatalogue && activeCatalogue.level < 3 &&
                    <div className='col-12 mb-10'>
                      <h2 className='mb-4'>
                        {t('CATALOGUES')}
                      </h2>
                      <CatalogueBoxes
                        openPopup={openPopup}
                        catalogues={subCatalogues}
                        currentUserRole={currentUserRole}
                        currentUserId={userId}
                        isCatalogueEditMode={isCatalogueEditMode}
                        editMode={editMode}
                        updateCatalogueName={updateCatalogueName}
                        editedCatalogueId={editedCatalogueId}
                        selectedCatalogueId={selectedCatalogueId}
                        selectedCatalogue={(catalogue, event, checked) => {
                          this.setSelectedCatalogueId(catalogue, checked);
                        }}
                        pageWithSubCatalogues={pageWithSubCatalogues}
                        editBtnAction={(key, event, cat) => {
                          this.setCataloguesEditMode(key, event);
                          this.setEditModeState();
                          this.setState({ editedCatalogueId: cat.id });
                        }}
                        parentCatalogueId={activeCatalogue ? activeCatalogue.id : ''}
                      />
                      <MoveCatalogue
                        btnTitle='Move catalogue'
                        options={this.getSearchCataloguesValue()}
                        isDisabled={!selectedCatalogueId}
                        disabledSubmitBtn={!destinationCatalogueId}
                        onInputChange={value => this.setState({ searchCatalogueQuery: value })}
                        submitDestination={() => this.updateCatalogueDestination()}
                        project={project}
                        customOptions
                      />
                      {updateCatalogueDestinationLoading &&
                        <Preloader text='Please wait. The catalogue is moved.' />
                      }
                    </div>
                  }
                  <div className='col-12 col-md-12 col-xs-12 mb-10'>
                    <DownloadList
                      downloadType
                      files={files}
                      years={years}
                      userId={userId}
                      project={project}
                      activeCatalogue={activeCatalogue}
                      parentCatalogues={this.getSearchCataloguesValue()}
                    />
                  </div>
                  <div className='col-12 col-md-12 col-xs-12 mb-10'>
                    <DownloadList
                      downloadType={false}
                      files={files}
                      years={years}
                      userId={userId}
                      openPopup={openPopup}
                      project={project}
                      activeCatalogue={activeCatalogue}
                      parentCatalogues={this.getSearchCataloguesValue()}
                    />
                  </div>
                </React.Fragment>
              )}
          </div>
        </div>
      </div>
    );
  }
}

Download.contextTypes = {
  t: PropTypes.func.isRequired
};

Download.defaultProps = {
};

Download.propTypes = {
  match: PropTypes.object.isRequired,
  getFiles: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  download: PropTypes.object.isRequired,
  updateCatalogueName: PropTypes.func.isRequired,
  getSubCatalogues: PropTypes.func.isRequired,
  destinationCatalogueId: PropTypes.string.isRequired,
  getPossibleParentCatalogues: PropTypes.func.isRequired,
  updateCatalogueDestination: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  getActiveCatalogue: PropTypes.func.isRequired
};

export default Download;
