import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Actions
import * as actions from '../actions';

import Authorization from '../components/authorization';

const mapStateToProps = state => {
  const {
    home
  } = state;

  return {
    home
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Authorization));
