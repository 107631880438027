import {
  ROUTE_CHANGE,
  APP_INITIALIZE,
} from './actions';

const INITIAL_STATE = {
  currentPath: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ROUTE_CHANGE:
      return {
        ...state,
        currentPath: action.payload,
        params: action.params !== false ? action.params : null
      };
    case APP_INITIALIZE:
      return {
        ...state,
        currentPath: action.payload,
      };

    default:
      return state;
  }
}
