let history

if (typeof document !== 'undefined') {
  if (!process.env.IS_BROWSER) {
    const createMemoryHistory = require('history/createMemoryHistory').default;
    history = createMemoryHistory({
      initialEntries: ['/']
    })
  } else {
    const createBrowserHistory = require('history/createBrowserHistory').default;
    history = createBrowserHistory();
  }
}

export default history