import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class Header extends Component {
  componentWillMount() {
    const {
      getAllInformations,
      match: { params }
    } = this.props;

    if (params && params.id) {
      getAllInformations(params.id);
    }
  }

  getNavigationLink = () => {
    const {
      catalogueActive,
      match: {
        params: { id }
      }
    } = this.props;
    let link = '/projects';
    if (catalogueActive && catalogueActive.level > 0) {
      link = `/projects/${ id }/catalogue/${ catalogueActive.parentCatalogueId }`;
    } else if (catalogueActive && catalogueActive.level === 0) {
      link = `/projects/${ id }/catalogue`;
    }

    return link;
  };

  render() {
    const { t } = this.context;
    const {
      info,
      infoLoading,
      download,
      removeBtn,
      remove,
      catalogueName,
      removeBtnAdditionalClass
    } = this.props;

    return (
      <div className='files__header'>
        <div>
          <Link
            to={this.getNavigationLink()}
            className='btn link'
          >
            <i className='i i-left-open ml-0' />
            {download ? 'Catalogues' : 'Projects'}
          </Link>
        </div>
        {!infoLoading && (
          <React.Fragment>
            {info.clientName && (
              <div>
                <span>{t('CLIENT')}</span>
                <p>{info.clientName}</p>
              </div>
            )}
            {info.countryName && (
              <div>
                <span>{t('COUNTRY')}</span>
                <p>{info.countryName}</p>
              </div>
            )}
            {info.agencyName && (
              <div>
                <span>{t('AGENCY')}</span>
                <p>{info.agencyName}</p>
              </div>
            )}
            {info.name && (
              <div>
                <span>{t('PROJECT_NAME')}</span>
                <p>{info.name}</p>
              </div>
            )}
          </React.Fragment>
        )}
        {catalogueName && (
          <div>
            <span>Catalogue Name</span>
            <p>{catalogueName}</p>
          </div>
        )}
        {removeBtn && remove && (
          <div>
            <button type='button' className={`btn red mini ${ removeBtnAdditionalClass }`} onClick={remove}>
              {removeBtn}
              <i className='i i-trash' />
            </button>
          </div>
        )}
      </div>
    );
  }
}

Header.contextTypes = {
  t: PropTypes.func.isRequired
};
Header.defaultProps = {
  download: false,
  removeBtn: false,
  remove: false,
  catalogueName: false,
  removeBtnAdditionalClass: '',
  catalogueActive: null
};
Header.propTypes = {
  info: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  infoLoading: PropTypes.bool.isRequired,
  removeBtn: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  catalogueName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  remove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  download: PropTypes.bool,
  getAllInformations: PropTypes.func.isRequired,
  removeBtnAdditionalClass: PropTypes.string,
  catalogueActive: PropTypes.object
};

export default withRouter(Header);
