import React, { Component } from 'react';
import { Protected } from '@src/components/decorators';
import { Link } from 'react-router-dom';
import { common } from '@src/config';

@Protected(false)
export default class Error404 extends Component {
  render() {
    const { t } = this.context;
    return (
      <div
        id='error'
        style={{
          background: `url('${ common.backgrounds[5] }')`
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='text-center animated delay-200ms flipInX'>
                Ooops!
                <br />
                <small>That page couldn't be found.</small>
              </h1>
              <p className='text-center mt-10  animated delay-400ms flipInX'>
                <span>Mistakes are the portals of discovery.</span>
                <br />
                <span>
                  But we'd rather you use out&nbsp;
                  <Link to='/' className='btn link'>
                    homepage
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}