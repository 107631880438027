import fixCollection from '@src/utils/fix-collection';
import {
  GET_USERS_REQ,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  CREATE_USER_REQ,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  GET_USER_ID_REQ,
  GET_USER_ID_SUCCESS,
  GET_USER_ID_FAILED,
  UPDATE_USER_ID_REQ,
  UPDATE_USER_ID_SUCCESS,
  UPDATE_USER_ID_FAILED,
  GET_USER_FILES_REQ,
  GET_USER_FILES_SUCCESS,
  GET_USER_FILES_FAILED,
  UPDATE_USER_ACTIVITY_REQUEST,
  UPDATE_USER_ACTIVITY_SUCCESS,
  UPDATE_USER_ACTIVITY_FAILED
} from './actions';

const INITIAL_STATE = {
  users: [],
  usersLoading: false,
  usersError: false,
  user: {},
  userLoading: false,
  userError: false,
  created: false,
  userUpdated: false,
  userUpdateActivityRequest: false,
  userUpdateActivitySuccess: false,
  userUpdateActivityError: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_USER_REQ:
      return {
        ...state,
        created: false
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        created: true
      };
    case CREATE_USER_FAILED:
      return {
        ...state,
        created: true
      };
    case GET_USERS_REQ:
      return {
        ...state,
        usersLoading: true
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: fixCollection(action.payload, 'user'),
        usersLoading: false
      };
    case GET_USERS_FAILED:
      return {
        ...state,
        usersError: true,
        usersLoading: false
      };
    case GET_USER_ID_REQ:
      return {
        ...state,
        user: {},
        userLoading: true
      };
    case GET_USER_ID_SUCCESS:
      return {
        ...state,
        user: action.payload,
        userLoading: false
      };
    case GET_USER_ID_FAILED:
      return {
        ...state,
        user: {},
        userError: true,
        userLoading: false
      };
    case UPDATE_USER_ID_REQ:
      return {
        ...state,
        userUpdated: false
      };
    case UPDATE_USER_ID_SUCCESS:
      return {
        ...state,
        userUpdated: true
      };
    case UPDATE_USER_ID_FAILED:
      return {
        ...state,
        userUpdated: false
      };
    case UPDATE_USER_ACTIVITY_REQUEST:
      return {
        ...state,
        userUpdateActivityRequest: true,
        userUpdateActivitySuccess: false,
        userUpdateActivityError: null
      };
    case UPDATE_USER_ACTIVITY_SUCCESS:
      return {
        ...state,
        userUpdateActivityRequest: false,
        userUpdateActivitySuccess: true,
        userUpdateActivityError: null
      };
    case UPDATE_USER_ACTIVITY_FAILED:
      return {
        ...state,
        userUpdateActivityRequest: false,
        userUpdateActivitySuccess: false,
        userUpdateActivityError: action.payload
      };
    default:
      return state;
  }
}
