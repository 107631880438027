import React, { Component } from 'react';
import { reduxForm, formValues } from 'redux-form';
import PropTypes from 'prop-types';
import { common } from '@src/config';
import { Preloader } from '@src/components/shared';
import Login from './login';
import ForgotPassword from './forgot-password';

class Authorization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reset: false
    };
  }

  componentDidUpdate(prevProps) {
    const {
      home: { logged, loginLoading },
      history
    } = this.props;

    const {
      home: { logged: prevLogged }
    } = prevProps;

    if (logged && !prevLogged && !loginLoading) {
      history.push('/projects');
    }
  }

  submitLoginForm = data => {
    const { user, pass } = data;
    const { authenticate } = this.props;

    if (this.state.reset) {
      this.submitResetPassword();
    } else {
      return authenticate(user, pass);
    }
  };

  submitResetPassword = () => {
    const { user, reset, resetPassword } = this.props;

    this.setState({
      reset: false
    });
    reset('loginForm');
    resetPassword(user);
  };

  render() {
    const { t } = this.context;
    const {
      home: { loginLoading },
      handleSubmit
    } = this.props;

    const random =
      common.backgrounds[
        Math.floor((Math.random() * common.backgrounds.length) - 1) + 1
      ];

    return (
      <div
        id='home'
        style={{
          background: `url('${
            window.location + process.env.NODE_ENV !== 'production'
              ? common.backgrounds[5]
              : random
          }')`
        }}
      >
        <div className='container'>
          <div className='row animated delay-200ms flipInX'>
            <div className='col-6'>
              <h1 className='mb-6'>{t('WELCOME_TO_STARS')}</h1>
              <p>{t('UNDER_WELCOME')}</p>
            </div>
          </div>
          <div className='row mt-10'>
            <div className='col-6'>
              <form
                onSubmit={handleSubmit(this.submitLoginForm)}
                id='loginForm'
              >
                {loginLoading && (
                  <Preloader text='Please wait. Logging in into the system...' />
                )}
                {!this.state.reset ?
                  <Login
                    forgotPasswordAction={() => this.setState({ reset: true })}
                  />
                  :
                  <ForgotPassword
                    submitResetPassword={() => this.submitResetPassword()}
                    cancelResetPassword={() => this.setState({ reset: false })}
                  />
                }
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Authorization.contextTypes = {
  t: PropTypes.func.isRequired
};

Authorization.defaultProps = {
  user: ''
}

Authorization.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  user: PropTypes.string,
  reset: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  authenticate: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const AuthorizationWithForm = reduxForm({
  form: 'loginForm',
  onSubmitFail: () => {
    // errorScroll();
  }
})(formValues('user')(Authorization));

export default AuthorizationWithForm;
