import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { SelectAutocomplete } from '@src/components/shared';

class MoveCatalogue extends Component {
  submitDestination = () => {
    const { submitDestination } = this.props;
    submitDestination();
  }
  render() {
    const {
      name,
      btnTitle,
      isDisabled,
      onInputChange,
      handleSubmit,
      options,
      disabledSubmitBtn,
      project,
      customOptions
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.submitDestination)}>
        <div className='row'>
          <div className='col-xs-8 col-md-6'>
            <SelectAutocomplete
              name={name}
              isDisabled={isDisabled}
              onInputChange={onInputChange}
              options={options}
              project={project}
              customOptions={customOptions}
            />
          </div>
          <div className='col-xs-4 col-md-6'>
            <button
              className='btn'
              type='submit'
              disabled={disabledSubmitBtn}
            >
              <span className='title'>{btnTitle}</span>
            </button>
          </div>
        </div>
      </form>
    );
  }
}

MoveCatalogue.defaultProps = {
  name: 'destinationCatalogue',
  isDisabled: true,
  disabledSubmitBtn: true,
  options: [],
  customOptions: false
};

MoveCatalogue.propTypes = {
  name: PropTypes.string,
  btnTitle: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitDestination: PropTypes.func.isRequired,
  options: PropTypes.array,
  disabledSubmitBtn: PropTypes.bool,
  project: PropTypes.object.isRequired,
  customOptions: PropTypes.bool
};

const MoveCatalogueWithForm = reduxForm({
  form: 'moveCatalogue',
  onSubmitFail: () => {
    // errorScroll();
  }
})(MoveCatalogue);

export default MoveCatalogueWithForm;
