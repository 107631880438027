import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Projects } from '@src/components/projects';
import { Protected } from '@src/components/decorators';

@Protected(true)
class ProjectsPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>My Projects | STARS Gateway</title>
        </Helmet>
        <Projects />
      </React.Fragment>
    );
  }
}

export default ProjectsPage;