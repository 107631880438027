import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { required, sizeVal } from '@src/utils/validators';
import { Field, Select, Preloader } from '@src/components/shared';

class NewProject extends Component {
  state = {
    options: null
  };

  componentWillMount() {
    const { getOptions } = this.props;

    getOptions();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.optionsLoading && !nextProps.optionsLoading) {
      this.renderSelectOptions(nextProps);
    }
    if (!this.props.created && nextProps.created) {
      this.props.history.push('/projects');
    }
  }

  submitNewProjectForm = data => {
    const { createProject } = this.props;

    const parsedData = {
      name: typeof data.name !== typeof undefined ? data.name : '',
      active: true,
      agencyId: typeof data.agency !== typeof undefined ? data.agency : '',
      clientId: typeof data.client !== typeof undefined ? data.client : '',
      countryId: typeof data.country !== typeof undefined ? data.country : ''
    };
    createProject(parsedData);
  };

  renderSelectOptions = nextProps => {
    const { options } = nextProps;
    const agencies = map(options[0], (a, k) => {
      return {
        key: k,
        label: a.name,
        id: a.id
      };
    });
    const clients = map(options[1], (a, k) => {
      return {
        key: k,
        label: a.name,
        id: a.id
      };
    });
    const countries = map(options[2], (a, k) => {
      return {
        key: k,
        label: a.name,
        id: a.id
      };
    });

    this.setState({
      options: {
        agencies: sortBy(agencies, 'label'),
        clients: sortBy(clients, 'label'),
        countries: sortBy(countries, 'label')
      }
    });
  };

  sizeVal = sizeVal();

  render() {
    const {
      context: { t },
      props: { handleSubmit },
      state: { options }
    } = this;

    return (
      <div id='new-projects'>
        <div className='container'>
          <div className='row gutter'>
            <div className='col-12'>
              <h2 className='mb-8 mt-8'>Create new project</h2>
            </div>
            {options === null ? (
              <Preloader text='Loading projects...' />
            ) : (
              <div className='col-12'>
                <form
                  onSubmit={handleSubmit(this.submitNewProjectForm)}
                  id='newProjectForm'
                >
                  <div className='row gutter'>
                    <div className='col-12'>
                      <div className='row flex flex-top mb-5'>
                        <div className='col-12'>
                          <h3 className='title'>Projects Base Informations</h3>
                        </div>
                        <div className='col-12'>
                          <Field
                            type='text'
                            name='name'
                            text='Name'
                            className='big'
                            validate={[required, this.sizeVal]}
                          />
                        </div>
                      </div>
                      <div className='row flex flex-top'>
                        <div className='col-12'>
                          <h3 className='title'>Projects Details</h3>
                        </div>
                        <div className='col-xs-12 col-md-6 col-4'>
                          <Select
                            data={options.clients}
                            text='Choose Client'
                            name='client'
                            validate={[required]}
                          />
                        </div>
                        <div className='col-xs-12 col-md-6 col-4'>
                          <Select
                            data={options.countries}
                            text='Choose Country'
                            name='country'
                            validate={[required]}
                          />
                        </div>
                        <div className='col-xs-12 col-md-6 col-4'>
                          <Select
                            data={options.agencies}
                            text='Choose Agency'
                            name='agency'
                            validate={[required]}
                          />
                        </div>
                      </div>
                      <div className='row flex flex-end mt-5'>
                        <div className='col-12'>
                          <button className='btn primary' type='submit'>
                            Add new project
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

NewProject.contextTypes = {
  t: PropTypes.func.isRequired
};
NewProject.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  createProject: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  optionsLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  created: PropTypes.bool.isRequired
};

const NewProjectWithForm = reduxForm({
  form: 'newProjectForm',
  onSubmitFail: () => {
    // errorScroll();
  }
})(NewProject);

export default NewProjectWithForm;
