import React, { Component } from 'react';
import { Switch, Link } from 'react-router-dom';
import { AppRoute } from '@src/router';

// import layouts
import { Layout, ScrollToTop } from '@src/components/layouts';
import { ToastContainer } from 'react-toastify';

// import pages
import {
  Home,
  Download,
  Catalogue,
  Users,
  Projects,
  PasswordReset,
  UsersManage,
  // New
  NewProject,
  NewUser,
  // Basic
  Privacy,
  Cookies,
  Error404,
  Error403,
  AuthorizationPage
} from '@src/pages';

// import popup
import {
  Popup,
  Welcome,
  UploadFile,
  NewFolder,
  Confirm,
  ConfirmDeactivation
} from '@src/components/popup';

import { createCookie, readCookie } from '@src/utils/cookies';

class App extends Component {
  state = {
    showCookies: false
  };

  componentDidMount() {
    this.initCookies();
  }

  initCookies = () => {
    const cookie = readCookie('accept-cookies');

    this.setState({
      showCookies: !cookie
    });
  };

  renderCookiesBar = () => (
    <div id='cookies-policy'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <p>
              We use cookies and similar technologies to recognize your repeat
              visits and preferences, as well as to analyze traffic. To learn
              more about cookies, including how to disable them, view our&nbsp;
              <Link to='/cookie-policy'>Cookie Policy</Link>. By clicking
              <em>"Accept Cookies"</em> on this banner, or using our site, you
              consent to the use of cookies unless you have disabled them.
            </p>
          </div>
          <div className='col-12 text-right mt-3'>
            <Link to='/cookie-policy' className='btn link'>
              Cookie policy
            </Link>
            <button
              className='btn primary'
              onClick={() => {
                createCookie('accept-cookies', 'true', 365);
                this.setState({ showCookies: false });
              }}
            >
              Accept cookies
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    return (
      <ScrollToTop>
        <Switch>
          {/* Main Pages */}
          <AppRoute
            exact
            path='/'
            layout={Layout}
            component={Home}
            type='main'
          />
          <AppRoute
            exact
            path='/login'
            layout={Layout}
            component={AuthorizationPage}
            type='main'
          />
          <AppRoute
            exact
            path='/projects'
            layout={Layout}
            component={Projects}
          />
          <AppRoute
            exact
            path='/projects/:id/catalogue'
            layout={Layout}
            component={Catalogue}
          />
          <AppRoute
            exact
            path='/projects/:id/catalogue/:catalogue'
            layout={Layout}
            component={Download}
          />
          <AppRoute
            exact
            path='/projects/create'
            layout={Layout}
            component={NewProject}
          />
          <AppRoute exact path='/users' layout={Layout} component={Users} />
          <AppRoute
            path='/users/:id/settings'
            layout={Layout}
            component={UsersManage}
          />
          <AppRoute
            exact
            path='/users/create'
            layout={Layout}
            component={NewUser}
          />
          <AppRoute
            path='/password-reset'
            layout={Layout}
            component={PasswordReset}
            type='main'
          />
          {/* Basic Pages */}
          <AppRoute
            path='/privacy-policy'
            layout={Layout}
            component={Privacy}
          />
          <AppRoute
            path='/cookie-policy'
            layout={Layout}
            component={Cookies}
          />
          <AppRoute
            layout={Layout}
            path='/error-403'
            component={Error403}
            type='main'
          />
          <AppRoute
            layout={Layout}
            component={Error404}
            type='main'
          />
        </Switch>
        <ToastContainer autoClose={2000} />
        <Popup>
          <Welcome id='Welcome' />
          <UploadFile id='UploadFile' />
          <NewFolder id='NewFolder' />
          <Confirm id='Confirm' />
          <ConfirmDeactivation id='ConfirmDeactivation' />
        </Popup>
        {this.state.showCookies && this.renderCookiesBar()}
      </ScrollToTop>
    );
  }
}

export default App;
