import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { openPopup } from '@src/components/popup/actions';
import * as actions from '../actions';
import List from '../components/list';

import {
  getFilesFormValues,
  getAllDownloadFiles,
  getAllUploadFiles,
  getDownloadFileDestinationCatalogue,
  getUploadFileDestinationCatalogue
} from '../selectors';

const mapStateToProps = state => {
  const {
    download,
    home,
    router: { params: routerParams }
  } = state;

  const formValues = getFilesFormValues(state);
  const checkAll = {
    download: getAllDownloadFiles(state),
    upload: getAllUploadFiles(state)
  };

  const downloadFileDestinationCatalogue = getDownloadFileDestinationCatalogue(state);
  const uploadFileDestinationCatalogue = getUploadFileDestinationCatalogue(state);

  return {
    download,
    routerParams,
    checkAll,
    home,
    formValues,
    downloadFileDestinationCatalogue,
    uploadFileDestinationCatalogue
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
      openPopup
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
