import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class ConfirmDeactivation extends Component {
  deactivateItem = () => {
    const {
      closePopup,
      data: {
        confirmDeactivateAction
      }
    } = this.props;

    closePopup();
    confirmDeactivateAction();
  }
  render() {
    const {
      closePopup,
      data: {
        deactivateObjectName,
        objectActiveStatus
      }
    } = this.props;

    return (
      <div id='ConfirmDeactivationPopup'>
        <h3 className='mb-4'>Confirm {objectActiveStatus ? 'deactivation' : 'activation'}</h3>
        <p className='mb-10'>
          Are you sure? <br />
          The {deactivateObjectName} will be {objectActiveStatus ? 'deactivated' : 'activated'}.<br />
          <Fragment>
            {deactivateObjectName === 'project' &&
              'It will be possible to activate it in the admin panel.'
            }
          </Fragment>
        </p>
        <div className='row'>
          <div className='col-12 text-right'>
            <button
              className='btn blank'
              onClick={() => {
                closePopup();
              }}
            >
              Cancel
            </button>
            <button
              className={`btn ${ objectActiveStatus ? 'red' : 'primary' }`}
              onClick={this.deactivateItem}
            >
              Yes, {objectActiveStatus ? 'deactivate' : 'activate'}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ConfirmDeactivation.defaultProps = {
  data: {
    deactivateObjectName: '',
    objectActiveStatus: false
  }
};

ConfirmDeactivation.propTypes = {
  closePopup: PropTypes.func.isRequired,
  data: PropTypes.object
};
export default ConfirmDeactivation;
