/* eslint-disable */
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Actions
import * as actions from '../actions'
import { authenticationLogout } from '@src/components/authorization'

import Header from '../components/header'

const mapStateToProps = state => {
  const {
    home: { logged, login, loginLoading, role },
    router: { currentPath: path }
  } = state

  return {
    logged,
    login,
    loginLoading,
    role,
    path
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
      authenticationLogout
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
