import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { common } from '@src/config';
import { Field } from '@src/components/shared';
import { required, password as passwordValidate } from '@src/utils/validators';

const validate = (values) => {
  const {
    newpassword, confirmPassword
  } = values;
  const errors = {};

  if (
    !!newpassword &&
    !!confirmPassword &&
    newpassword !== confirmPassword
  ) {
    errors.confirmPassword = 'DIFFERENT_PASSWORDS';
  }

  return errors;
};

class PasswordReset extends Component {
  componentDidMount(){
    const {
      location
    } = this.props;

    if( location.search ) {
      this.setState({
        token: location.search.split('token=')[1]
      })
    }
  }

  submitPasswordReset = (data) => {
    const { newpassword } = data;
    const { token } = this.state;
    const { setNewPassword } = this.props;

    return setNewPassword( newpassword, token );
  }

  render() {
    const { t } = this.context;
    const {
      handleSubmit,
      resetDone
    } = this.props;

    let random = common.backgrounds[Math.floor(Math.random() * common.backgrounds.length-1) + 1];

    if(resetDone) {
      return <Redirect to='/' />
    }

    return (
      <div
        id='passwordReset'
        style={{
          background: `url('${ window.location + process.env.NODE_ENV !== 'production' ? common.backgrounds[5] : random }')`
        }}
      >
        <div className='container'>
          <div className='row animated delay-200ms flipInX'>
            <div className='col-6'>
              <h1 className='mb-6'>
                { t('WELCOME_TO_STARS') }
              </h1>
              <p>
                { t('UNDER_WELCOME') }
              </p>
            </div>
          </div>
          <div className='row mt-10'>
            <div className='col-6'>
              <form onSubmit={handleSubmit(this.submitPasswordReset)} id="passwordResetForm">
                <div className='row animated delay-400ms flipInX'>
                  <div className='col-5'>
                    <Field
                      name='newpassword'
                      type='password'
                      text={ t('NEW_PASSWORD') }
                      validate={[required, passwordValidate]}
                    />
                  </div>
                  <div className='col-5'>
                    <Field
                      name='confirmPassword'
                      type='password'
                      text={ t('CONFIRM_PASSWORD') }
                      validate={[required, passwordValidate]}
                    />
                  </div>
                  <div className='col-2'>
                    <button
                      type='submit'
                      className='btn login mt-4'
                    >
                      { t('SAVE') }
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PasswordReset.contextTypes = {
  t: PropTypes.func.isRequired,
};

const PasswordResetWithForm = reduxForm({
  validate,
  form: 'passwordResetForm',
})(PasswordReset);


export default withRouter(PasswordResetWithForm);
