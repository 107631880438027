import api from '@src/config/api';
import { toast } from 'react-toastify';

// GET PROJECTS
export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAILED = 'GET_PROJECT_FAILED';

// CREATE PROJECTS
export const CREATE_PROJECT_REQ = 'CREATE_PROJECT_REQ';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED';

// GET OPTIONS
export const GET_OPTIONS_REQUEST = 'GET_OPTIONS_REQUEST';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';
export const GET_OPTIONS_FAILED = 'GET_OPTIONS_FAILED';

export function getProjects() {
  return dispatch => {
    dispatch({ type: GET_PROJECT_REQUEST });

    api('/projects/users')
      .then(res => {
        dispatch({
          type: GET_PROJECT_SUCCESS,
          payload: res
        });
      })
      .catch(() => {
        dispatch({ type: GET_PROJECT_FAILED });
      });
  };
}
export function createProject(data) {
  return dispatch => {
    dispatch({ type: CREATE_PROJECT_REQ });

    // eslint-disable-next-line
    return api('/projects', {
      method: 'POST',
      data
    })
      .then(res => {
        dispatch({
          type: CREATE_PROJECT_SUCCESS,
          payload: res
        });
        toast.success('The project have been successfully saved', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(() => {
        dispatch({ type: CREATE_PROJECT_FAILED });
      });
  };
}

export const getAgency = () => {
  return api('/agencies', {
    method: 'GET'
  });
};
export const getClient = () => {
  return api('/clients', {
    method: 'GET'
  });
};
export const getCountry = () => {
  return api('/countries', {
    method: 'GET'
  });
};
export function getOptions() {
  return dispatch => {
    dispatch({
      type: GET_OPTIONS_REQUEST
    });
    Promise.all([getAgency(), getClient(), getCountry()]).then(res => {
      dispatch({
        type: GET_OPTIONS_SUCCESS,
        payload: res
      });
    });
  };
}