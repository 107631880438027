import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { checkAuthentication } from '@src/components/authorization';

// Actions
import * as actions from '../actions';

import Home from '../components/home';

const mapStateToProps = state => {
  const {
    home
  } = state;

  return {
    home
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  checkAuthentication
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);