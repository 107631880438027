import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { required, max20 } from '@src/utils/validators';
import { Field } from '@src/components/shared';

class NewFolder extends Component {
  submitNewFolder = data => {
    const {
      saveCatalogue,
      data: {
        parentCatalogueId
      },
      routerParams: { id: projectId }
    } = this.props;
    saveCatalogue(data.name, projectId, parentCatalogueId);
  };

  render() {
    const { t } = this.context;
    const { handleSubmit } = this.props;

    return (
      <div id='UploadFile'>
        <h3 className='mb-8'>Create new catalogue</h3>
        <form onSubmit={handleSubmit(this.submitNewFolder)} id='newFolder'>
          <div className='row'>
            <div className='col-12'>
              <Field
                type='text'
                name='name'
                text='Catalogue Name'
                className='big'
                validate={[required, max20]}
              />
            </div>
            <div className='col-12 text-right mt-5'>
              <button className='btn primary' type='submit'>
                Save catalogue
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

NewFolder.contextTypes = {
  t: PropTypes.func.isRequired
};

NewFolder.defaultProps = {
  data: {
    parentCatalogueId: ''
  }
};

NewFolder.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  routerParams: PropTypes.object.isRequired,
  saveCatalogue: PropTypes.func.isRequired,
  data: PropTypes.object
};

const NewFolderWithForm = reduxForm({
  form: 'newFolder',
  onSubmitFail: () => {
    // errorScroll();
  }
})(NewFolder);

export default NewFolderWithForm;
