import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import AsyncSelect from 'react-select/async';

class TMSelectAutocompleteComponent extends Component {
  onChange = (value) => {
    const { input } = this.props;
    if (value != null) {
      input.onChange(value);
    } else {
      input.onChange(null);
    }
  };
  filterOptions = inputValue => {
    const { options } = this.props;
    return options.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase()));
  };
  loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(this.filterOptions(inputValue));
    }, 1000);
  };

  renderCustomOptions = () => {
    const {
      options,
      project
    } = this.props;

    return (
      [
        {
          value: 'main_level',
          label: <div className='main-level-catalogue'>{`${ project ? project.projectName : '' }`}</div>
        },
        ...options
      ]
    );
  };

  render() {
    const {
      meta,
      isDisabled,
      input,
      onInputChange,
      customOptions,
      options
    } = this.props;

    return (
      <div className={meta.touched && meta.error ? 'error' : ''}>
        <AsyncSelect
          {...input}
          isDisabled={isDisabled}
          placeholder='Search catalogue'
          searchPromptText='Search catalogue'
          loadingPlaceholder='Loading'
          noResultsText='No catalogues'
          className={`select-autocomplete ${ isDisabled ? 'select-autocomplete--disable' : '' }`}
          isClearable
          defaultOptions={customOptions ? this.renderCustomOptions() : options}
          cacheOptions={false}
          loadOptions={(inputValue, callback) => this.loadOptions(inputValue, callback)}
          onChange={value => this.onChange(value)}
          onInputChange={value => onInputChange(value)}
          onBlur={() => input.onBlur(input.value)}
        />
        {meta.touched && meta.error && <div>{meta.error}</div>}
      </div>
    );
  }
}

TMSelectAutocompleteComponent.defaultProps = {
  isDisabled: false,
  options: [],
  project: null,
  customOptions: false
};

TMSelectAutocompleteComponent.propTypes = {
  meta: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  options: PropTypes.array,
  onInputChange: PropTypes.func.isRequired,
  project: PropTypes.object,
  customOptions: PropTypes.bool
};

const TMSelectAutocomplete = props => (
  <Field name={props.name} component={TMSelectAutocompleteComponent} {...props} />
);

TMSelectAutocomplete.propTypes = {
  name: PropTypes.string.isRequired
};

export default TMSelectAutocomplete;
