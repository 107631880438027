// Main pages
export { default as Home } from './home';
export { default as Download } from './download';
export { default as Catalogue } from './catalogue';
export { default as Projects } from './projects';
export { default as Users } from './users';
export { default as UsersManage } from './users-manage';

// Create new
export { default as NewProject } from './new-project';
export { default as NewUser } from './new-users';
// Reset passwords
export { default as PasswordReset } from './authorization/password-reset';
export { default as AuthorizationPage } from './authorization/authorization';
// Basic pages
export { default as Terms } from './basic/terms';
export { default as Privacy } from './basic/privacy';
export { default as Cookies } from './basic/cookies';
export { default as Error404 } from './basic/error';
export { default as Error403 } from './basic/error-403';
