import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Protected } from '@src/components/decorators';

@Protected(false)
export default class PrivacyPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Privacy Policy | STARS Gateway</title>
        </Helmet>
        <div id='privacy' className='textPage animated fadeIn'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div>
                  <h1>Privacy Policy</h1>
                  <p>
                    This page informs you of our policies regarding the
                    collection, use and disclosure of Personal Information when
                    you use our Service.
                  </p>
                  <p>
                    We will not use or share your information with anyone except
                    as described in this Privacy Policy.
                  </p>
                  <p>
                    We use your Personal Information for providing and improving
                    the Service. By using the Service, you agree to the
                    collection and use of information in accordance with this
                    policy. Don’t want to hear from us anymore? You may
                    unsubscribe at any time.
                  </p>
                </div>
                <div>
                  <h3>Information Collection And Use</h3>
                  <p>
                    While using our Service, we may ask you to provide us with
                    certain personally identifiable information that can be used
                    to contact or identify you. Personally identifiable
                    information may include, but is not limited to, your name
                    and email address ("Personal Information"). We collect this
                    information for the purpose of providing the Service,
                    identifying and communicating with you, responding to your
                    requests/enquiries, and improving our services.
                  </p>
                  <p>
                    We will not share Personal Information with other companies,
                    organizations, individuals or other third parties without
                    your prior consent unless disclosure is necessary to (i)
                    comply with legal process or other legal requirements or
                    enforceable request of any governmental authority; (ii)
                    protect and defend the rights or property of Stars Sp. z
                    o.o.; (iii) enforce this Privacy Policy, including
                    investigation of potential violations; (iv) protect the
                    interests of other users of the Service or any other person
                    as required or permitted by law; or (v) detect, prevent, or
                    otherwise address fraud, security or technical issues. You
                    acknowledge that you have no expectation of privacy with
                    respect to the Internet generally.
                  </p>
                </div>
                <div>
                  <h3>Service Providers</h3>
                  <p>
                    We may employ third party companies and individuals to
                    facilitate our Service, to provide the Service on our
                    behalf, to perform Service-related services and/or to assist
                    us in analyzing how our Service is used.
                  </p>
                  <p>
                    These third parties have access to your Personal Information
                    only to perform specific tasks on our behalf and are
                    obligated not to disclose or use your information for any
                    other purpose.
                  </p>
                </div>
                <div>
                  <h3>Communications</h3>
                  <p>
                    We may use your Personal Information to contact you with
                    incidental newsletters, marketing or promotional materials
                    and other information that may be of interest to you. You
                    may opt out of receiving any, or all, of these
                    communications from us by following the unsubscribe link or
                    instructions provided in any email we send.
                  </p>
                </div>
                <div>
                  <h3>Security</h3>
                  <p>
                    The security of your Personal Information is important to
                    us, and we strive to implement and maintain reasonable,
                    commercially acceptable security procedures and practices
                    appropriate to the nature of the information we store, in
                    order to protect it from unauthorized access, destruction,
                    use, modification, or disclosure.
                  </p>
                  <p>
                    However, please be aware that no method of transmission over
                    the internet, or method of electronic storage is 100% secure
                    and we are unable to guarantee the absolute security of the
                    Personal Information we have collected from you.
                  </p>
                </div>
                <div>
                  <h3>Changes To This Privacy Policy</h3>
                  <p>
                    This Privacy Policy is effective as of March 1, 2019 and
                    will remain in effect except with respect to any changes in
                    its provisions in the future, which will be in effect
                    immediately after being posted on this page.
                  </p>
                  <p>
                    We reserve the right to update or change our Privacy Policy
                    at any time and you should check this Privacy Policy
                    periodically. Your continued use of the Service after we
                    post any modifications to the Privacy Policy on this page
                    will constitute your acknowledgment of the modifications and
                    your consent to abide and be bound by the modified Privacy
                    Policy.
                  </p>
                  <p>
                    If we make any material changes to this Privacy Policy, we
                    will notify you through the email address you have provided
                    us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}