import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { common } from '@src/config';
import { Preloader } from '@src/components/shared';

class Home extends Component {
  state = {
    isWaiting: true
  }

  async componentDidMount() {
    await this.checkLoginStatus();
  }

  componentWillReceiveProps() {
    this.setState({ isWaiting: false });
  }

  checkLoginStatus = async () => {
    const { checkAuthentication } = this.props;
    await checkAuthentication();
  }

  render() {
    const {
      home: { logged, projectIds }
    } = this.props;

    const { isWaiting } = this.state;

    if (!isWaiting) {
      if (!logged) {
        return <Redirect to='/login' />;
      } else if (logged && Array.isArray(projectIds) && projectIds.length === 1) {
        return <Redirect to={`/projects/${ projectIds[0] }/catalogue`} />;
      } else if (logged) {
        return <Redirect to='/projects' />;
      }
    }

    const random =
      common.backgrounds[
        Math.floor((Math.random() * common.backgrounds.length) - 1) + 1
      ];

    return (
      <div
        id='home'
        style={{
          background: `url('${
            window.location + process.env.NODE_ENV !== 'production'
              ? common.backgrounds[5]
              : random
          }')`
        }}
      >
        <div className='container'>
          <div className='row animated delay-200ms flipInX'>
            <div className='col-12'>
              <Preloader text='Please wait...' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Home.contextTypes = {
  t: PropTypes.func.isRequired
};

Home.propTypes = {
  home: PropTypes.object.isRequired,
  checkAuthentication: PropTypes.func.isRequired
};

export default Home;