import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Catalogue } from '@src/components/download';
import { Protected } from '@src/components/decorators';

@Protected(true)
class CataloguePage extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Download Files | STARS Gateway</title>
        </Helmet>
        <Catalogue />
      </React.Fragment>
    );
  }
}

export default CataloguePage;