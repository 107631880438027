import React, { Component } from 'react';

class Preloader extends Component {
  render() {
    return (
      <div id="preloader" className={ this.props.className ? this.props.className : '' }>
        <div>
          <div className='ball' />
          { this.props.text ?
            <React.Fragment>
              <div>{this.props.text}</div>
            </React.Fragment>
            :
            null
          }
        </div>
      </div>
    );
  }
}

export default Preloader;
