import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkAuthentication } from '@src/components/authorization';

export default (secure = false) => Component => {
  class Authenticate extends Component {
    componentWillMount() {
      const { authenticated, checkAuthentication } = this.props;

      if (authenticated === null) {
        checkAuthentication();
      }
    }

    render() {
      const { location, authenticated } = this.props;

      const previousPath =
        location &&
        location.state &&
        location.state.hasOwnProperty('previousPath')
          ? location.state.previousPath
          : false;
      // Access to current component is denied
      let redirectUrl =
        previousPath && previousPath.pathname !== '/'
          ? previousPath
          : '/projects';

      if (secure && !authenticated) {
        redirectUrl = {
          pathname: '/'
        };

        if (location.pathname !== '/') {
          redirectUrl.state = { previousPath: location };
        }

        return <Redirect to={redirectUrl} />;
      }

      return <Component {...this.props} />;
    }
  }

  const mapStateToProps = state => {
    return {
      authenticated: state.home.logged
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      checkAuthentication: () => dispatch(checkAuthentication())
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Authenticate);
};