import api from '@src/config/api';
import map from 'lodash/map';
import { toast } from 'react-toastify';
import { reset, change } from 'redux-form';
import { closePopup } from '@src/components/popup/actions';

export const FILES_REQUEST = 'FILES_REQUEST';
export const FILES_SUCCESS = 'FILES_SUCCESS';
export const FILES_FAILED = 'FILES_FAILED';

export const INFO_REQUEST = 'INFO_REQUEST';
export const INFO_SUCCESS = 'INFO_SUCCESS';
export const INFO_FAILED = 'INFO_FAILED';

export const GET_FILES = 'GET_FILES';
export const GET_ALL_FILES = 'GET_ALL_FILES';
export const REMOVE_FILES = 'REMOVE_FILES';
export const REMOVE_ALL_FILES = 'REMOVE_ALL_FILE';

export const UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILED = 'UPDATE_STATUS_FAILED';

export const CATALOGUES_REQUEST = 'CATALOGUES_REQUEST';
export const CATALOGUES_SUCCESS = 'CATALOGUES_SUCCESS';
export const CATALOGUES_FAILED = 'CATALOGUES_FAILED';

export const SAVE_CATALOGUES_REQUEST = 'SAVE_CATALOGUES_REQUEST';
export const SAVE_CATALOGUES_SUCCESS = 'SAVE_CATALOGUES_SUCCESS';
export const SAVE_CATALOGUES_FAILED = 'SAVE_CATALOGUES_FAILED';

export const REMOVE_CATALOGUES_REQUEST = 'REMOVE_CATALOGUES_REQUEST';
export const REMOVE_CATALOGUES_SUCCESS = 'REMOVE_CATALOGUES_SUCCESS';
export const REMOVE_CATALOGUES_FAILED = 'REMOVE_CATALOGUES_FAILED';

export const DEACTIVATE_PROJECT_REQUEST = 'DEACTIVATE_PROJECT_REQUEST';
export const DEACTIVATE_PROJECT_SUCCESS = 'DEACTIVATE_PROJECT_SUCCESS';
export const DEACTIVATE_PROJECT_FAILED = 'DEACTIVATE_PROJECT_FAILED';

export const UPDATE_CATALOGUE_NAME_REQUEST = 'UPDATE_CATALOGUE_NAME_REQUEST';
export const UPDATE_CATALOGUE_NAME_SUCCESS = 'UPDATE_CATALOGUE_NAME_SUCCESS';
export const UPDATE_CATALOGUE_NAME_FAILED = 'UPDATE_CATALOGUE_NAME_FAILED';

export const SUB_CATALOGUES_REQUEST = 'SUB_CATALOGUES_REQUEST';
export const SUB_CATALOGUES_SUCCESS = 'SUB_CATALOGUES_SUCCESS';
export const SUB_CATALOGUES_FAILED = 'SUB_CATALOGUES_FAILED';

export const GET_POSSIBLE_PARENT_CATALOGUES_REQUEST = 'GET_POSSIBLE_PARENT_CATALOGUES_REQUEST';
export const GET_POSSIBLE_PARENT_CATALOGUES_SUCCESS = 'GET_POSSIBLE_PARENT_CATALOGUES_SUCCESS';
export const GET_POSSIBLE_PARENT_CATALOGUES_FAILED = 'GET_POSSIBLE_PARENT_CATALOGUES_FAILED';

export const UPDATE_CATALOGUE_DESTINATION_REQUEST = 'UPDATE_CATALOGUE_DESTINATION_REQUEST';
export const UPDATE_CATALOGUE_DESTINATION_SUCCESS = 'UPDATE_CATALOGUE_DESTINATION_SUCCESS';
export const UPDATE_CATALOGUE_DESTINATION_FAILED = 'UPDATE_CATALOGUE_DESTINATION_FAILED';

export const GET_ACTIVE_CATALOGUE_REQUEST = 'GET_ACTIVE_CATALOGUE_REQUEST';
export const GET_ACTIVE_CATALOGUE_SUCCESS = 'GET_ACTIVE_CATALOGUE_SUCCESS';
export const GET_ACTIVE_CATALOGUE_FAILED = 'GET_ACTIVE_CATALOGUE_FAILED';

export const changeAllCheckboxes = (bool, fields) => {
  return dispatch => map(fields, (n) => {
    dispatch(change('filesForm', n, bool));
  });
};

export const getAllInformations = projectId => {
  return dispatch => {
    dispatch({
      type: INFO_REQUEST
    });
    return api(`projects/${ projectId }`, {
      method: 'GET'
    })
      .then(res => {
        // Promise.all([
        //   res.agencyId ? getAgency(res.agencyId) : null,
        //   res.clientId ? getClient(res.clientId) : null,
        //   res.countryId ? getCountry(res.countryId) : null
        // ]).then(res => {
        dispatch({
          type: INFO_SUCCESS,
          payload: res
        });
        // });
      })
      .catch(e => {
        toast.error('An error occurred.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch({
          type: INFO_FAILED,
          payload: e
        });
      });
  };
};

export const getFiles = catalogueId => {
  return dispatch => {
    dispatch({
      type: FILES_REQUEST
    });

    Promise.all([getMarketFiles(catalogueId)])
      .then(res => {
        dispatch({
          type: FILES_SUCCESS,
          payload: res
        });
      })
      .catch(e => {
        toast.error('An error occurred.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch({
          type: FILES_FAILED,
          payload: e
        });
      });
  };
};

export const getCatalogues = projectId => {
  return dispatch => {
    dispatch({
      type: CATALOGUES_REQUEST
    });
    return api(`projects/${ projectId }/catalogues`, {
      method: 'GET'
    })
      .then(res => {
        dispatch({
          type: CATALOGUES_SUCCESS,
          payload: res
        });
      })
      .catch(e => {
        dispatch({
          type: CATALOGUES_FAILED,
          payload: e
        });
      });
  };
};

// Api queries
export const getMarketFiles = id => {
  return api(`catalogues/${ id }/files`, {
    method: 'GET'
  });
};
export const getMarkets = id => {
  return api(`market/${ id }`, {
    method: 'GET'
  });
};
export const getAgency = id => {
  return api(`agencies/${ id }`, {
    method: 'GET'
  });
};
export const getClient = id => {
  return api(`clients/${ id }`, {
    method: 'GET'
  });
};
export const getCountry = id => {
  return api(`countries/${ id }`, {
    method: 'GET'
  });
};

// ***********************
// rest of API calls
export const downloadFile = (id, name, type) => {
  return dispatch => {
    dispatch({
      type: GET_FILES
    });
    return api(`files/${ id }/content`, {
      method: 'GET',
      responseType: 'blob'
    })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(e => {
        toast.error(e.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      });
  };
};

export const removeFile = (id, marketId, reload = true) => {
  return dispatch => {
    dispatch({
      type: REMOVE_FILES
    });
    return api(`files/${ id }`, {
      method: 'DELETE'
    })
      .then(res => {
        if (reload) {
          toast.success('The files has been successfully removed.', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
          dispatch(getFiles(marketId));
        }
      })
      .catch(e => {
        toast.error('An error occurred while trying to remove the file.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      });
  };
};
// ***********************
// update status
export function updateFileStatus(fileId, fileStatus) {
  return dispatch => {
    dispatch({ type: UPDATE_STATUS_REQUEST });
    api(`/files/${ fileId }`, {
      method: 'PUT',
      fileStatus
    })
      // TODO: after request
      .then(res => {
        dispatch({
          type: UPDATE_STATUS_SUCCESS,
          payload: res
        });
      })
      .catch(() => {
        dispatch({ type: UPDATE_STATUS_FAILED });
      });
  };
}

export const getSubCatalogues = id => {
  return dispatch => {
    dispatch({
      type: SUB_CATALOGUES_REQUEST
    });
    return api(`catalogues/${ id }/sub-catalogues`, {
      method: 'GET'
    })
      .then(res => {
        dispatch({
          type: SUB_CATALOGUES_SUCCESS,
          payload: res
        });
      })
      .catch(e => {
        dispatch({
          type: SUB_CATALOGUES_FAILED,
          payload: e
        });
      });
  };
};

export const saveCatalogue = (name, projectId, parentCatalogueId) => {
  return dispatch => {
    dispatch({
      type: SAVE_CATALOGUES_REQUEST
    });

    // eslint-disable-next-line
    return api('/catalogues', {
      method: 'POST',
      data: {
        name,
        projectId,
        parentCatalogueId
      }
    })
      .then(res => {
        dispatch({
          type: SAVE_CATALOGUES_SUCCESS,
          payload: res
        });
        toast.success('The catalogue have been created.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch(closePopup());
        dispatch(parentCatalogueId ? getSubCatalogues(parentCatalogueId) : getCatalogues(projectId));
      })
      .catch(e => {
        toast.error('An error occurred.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch({
          type: SAVE_CATALOGUES_FAILED,
          payload: e
        });
      });
  };
};

export const removeCatalogue = (catalogueId, projectId, history) => {
  return dispatch => {
    dispatch({
      type: REMOVE_CATALOGUES_REQUEST
    });

    // eslint-disable-next-line
    return api(`catalogues/${catalogueId}`, {
      method: 'DELETE'
    })
      .then(res => {
        dispatch({
          type: REMOVE_CATALOGUES_SUCCESS,
          payload: res
        });
        dispatch(closePopup());
        if (typeof res !== typeof undefined) {
          dispatch(getCatalogues(projectId));
          history.goBack();
        }
      })
      .catch(e => {
        toast.error('An error occurred.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch({
          type: REMOVE_CATALOGUES_FAILED,
          payload: e
        });
      });
  };
};

export const resetForm = () => {
  return dispatch => {
    dispatch(reset('filesForm'));
  };
};

export const moveFile = (fileId, id) => {
  return api(`/files/${ fileId }/catalogue-change?catalogueId=${ id }`, {
    method: 'PUT'
  });
};

export const moveFilesToCatalogue = (catalogueId, marketId, array) => {
  return dispatch => {
    dispatch({
      type: 'MOVE_FILES_TO_CATALOGUE'
    });

    Promise.all(
      map(array, i => {
        moveFile(i, catalogueId);
      })
    )
      .then(() => {
        dispatch({
          type: 'MOVE_FILES_TO_CATALOGUE_SUCCESS'
        });
        toast.success('The files have been moved successfully.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setTimeout(() => {
          dispatch(resetForm());
          dispatch(getFiles(marketId));
        }, 500);
      })
      .catch(e => {
        toast.error('An error occurred.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      });
  };
};

export const changeFileStatus = (fileId, value) => {
  return dispatch => {
    dispatch({
      type: 'CHANGE_FILES_STATUS_REQUEST'
    });
    api(`/files/${ fileId }?fileStatus=${ value }`, {
      method: 'PUT'
    })
      .then(() => {
        dispatch({
          type: 'CHANGE_FILES_STATUS_SUCCESS'
        });
        // dispatch(resetForm());
      })
      .catch(e => {
        toast.error('An error occurred.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      });
  };
};

export const updateField = (name, value) => {
  return dispatch => {
    dispatch(change('filesForm', name, value));
  };
};

export const deactivateProject = (projectId) => {
  return dispatch => {
    dispatch({
      type: 'DEACTIVATE_PROJECT_REQUEST'
    });
    api(`/projects/${ projectId }/activity`, {
      method: 'PUT'
    })
      .then(() => {
        dispatch({
          type: 'DEACTIVATE_PROJECT_SUCCESS'
        });
        toast.success(
          'The project have been successfully deactivated',
          {
            position: toast.POSITION.BOTTOM_RIGHT
          }
        );
      })
      .catch(() => {
        toast.error('An error occurred.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      });
  };
};

export const updateCatalogueName = (catalogueId, catalogueName) => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_CATALOGUE_NAME_REQUEST'
    });
    api(`/catalogues/${ catalogueId }`, {
      method: 'PUT',
      data: catalogueName
    })
      .then(() => {
        dispatch({
          type: 'UPDATE_CATALOGUE_NAME_SUCCESS'
        });
        toast.success(
          'The catalogue name has been successfully updated',
          {
            position: toast.POSITION.BOTTOM_RIGHT
          }
        );
      })
      .catch(() => {
        toast.error('An error occurred.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      });
  };
};

export const getPossibleParentCatalogues = data => {
  return dispatch => {
    dispatch({
      type: GET_POSSIBLE_PARENT_CATALOGUES_REQUEST
    });
    return api(`catalogues/search?projectId=${ data.projectId }&catalogueId=${ data.catalogueId }&query=${ data.query }&fileDestination=${ data.fileDestination || false }`,
      {
        method: 'GET'
      })
      .then(res => {
        dispatch({
          type: GET_POSSIBLE_PARENT_CATALOGUES_SUCCESS,
          payload: res
        });
      })
      .catch(e => {
        dispatch({
          type: GET_POSSIBLE_PARENT_CATALOGUES_FAILED,
          payload: e
        });
      });
  };
};

export const updateCatalogueDestination = (catalogueId, destinationCatalogueId) => {
  const destinationCatalogue = destinationCatalogueId === 'main_level' ? '' : destinationCatalogueId;
  return dispatch => {
    dispatch({
      type: UPDATE_CATALOGUE_DESTINATION_REQUEST
    });
    return api(`catalogues/${ catalogueId }/catalogue-change?destinationCatalogueId=${ destinationCatalogue }`,
      {
        method: 'PUT'
      })
      .then(res => {
        if (typeof res === typeof undefined) {
          return dispatch({
            type: UPDATE_CATALOGUE_DESTINATION_FAILED,
            payload: 'error message'
          });
        }
        dispatch({
          type: UPDATE_CATALOGUE_DESTINATION_SUCCESS
        });
        toast.success(
          'The catalogue has been moved.',
          {
            position: toast.POSITION.BOTTOM_RIGHT
          }
        );

        return true;
      })
      .catch(e => {
        toast.error('An error occurred.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch({
          type: UPDATE_CATALOGUE_DESTINATION_FAILED,
          payload: e
        });
      });
  };
};


export const getActiveCatalogue = (catalogueId) => {
  return dispatch => {
    dispatch({
      type: GET_ACTIVE_CATALOGUE_REQUEST
    });
    return api(`catalogues/${ catalogueId }`,
      {
        method: 'GET'
      })
      .then(res => {
        dispatch({
          type: GET_ACTIVE_CATALOGUE_SUCCESS,
          payload: res
        });
      })
      .catch(e => {
        toast.error('An error occurred.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch({
          type: UPDATE_CATALOGUE_DESTINATION_FAILED,
          payload: e
        });
      });
  };
};
