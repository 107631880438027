import { formValueSelector, getFormValues } from 'redux-form';

const selector = formValueSelector('moveCatalogue');
const filesFormSelector = formValueSelector('filesForm');

export const getDestinationCatalogue = state => selector(state, 'destinationCatalogue');
export const getFilesFormValues = state => getFormValues('filesForm')(state);

export const getDownloadFileDestinationCatalogue = state => filesFormSelector(state, 'download-files-destination-catalogues');
export const getUploadFileDestinationCatalogue = state => filesFormSelector(state, 'upload-files-destination-catalogues');
export const getAllDownloadFiles = state => filesFormSelector(state, 'select-all-download');
export const getAllUploadFiles = state => filesFormSelector(state, 'select-all-upload');
