// eslint-disable
export const translations = {
  en: {
    Home: 'EN Home',
    LOREM:
      'Lorem ipsum dolor sit amet enim. Etiam ullamcorper.  Suspendisse a pellentesque dui, non felis. Maecenas malesuada elit lectus felis, Lesuada ultricies, curabitur et ligulat molestie a, ultricies',
    WELCOME_TO_STARS: 'Welcome to STARS Gateway',
    UNDER_WELCOME:
      'This is a secure global portal for advertisers and media agencies to safely upload, download and exchange files and data. You need to receive authorization details from STARS before you can access this restricted site.',
    USERNAME: 'Username',
    PASSWORD: 'Password',
    NEW_PASSWORD: 'New password',
    CONFIRM_PASSWORD: 'Confirm new password',
    DIFFERENT_PASSWORDS: 'Passwords do not match',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    TERMS: 'Terms',
    COOKIES: 'Cookies Policy',
    PRIVACY: 'Privacy Policy',
    STARS: 'Stars',
    COPYRIGHT: '© Stars Sp. z o.o. Copyright {n}. All rights reserved.',
    YOUR_FILES: 'Your files',
    FILES_TO_DOWNLOAD: 'Files to download',
    UPLOADED_FILES: 'Uploaded files',
    HOW_WORKS: 'How it works',
    DOWNLOAD: 'Download',
    EDIT: 'Edit',
    UPLOAD: 'Upload',
    UPDATED: 'Updated',
    DOWNLOAD_FILES: 'Download files',
    DOWNLOAD_SELECTED: 'Download selected',
    REMOVE_SELECTED: 'Remove selected',
    UPLOAD_NEW_FILE: 'Upload new file',
    DOWNLOAD_ALL: 'Download all',
    REMOVE_ALL: 'Remove all',
    EDIT_FILES: 'Edit files',
    UPLOAD_FILES: 'Upload file',
    DELETE: 'Delete',
    AGENCY: 'Agency',
    CLIENT: 'Client',
    COUNTRY: 'Country',
    PROJECTS: 'Projects',
    EMAIL: 'E-mail',
    DRAG_AND_DROP: 'Drag and drop the file you want to upload here',
    FORGOT_PASSWORD: 'Forgot your password',
    RESET_PASSWORD: 'Reset password',
    SAVE: 'Save',
    NO_FILES: 'No files to show. Upload one.',
    NO_FILES_TO_DOWNLOAD: 'No files to show.',
    CANCEL: 'Cancel',
    YOUR_PROJECTS: 'Your projects',
    USERS_PAGE: 'Manage users',
    USER: 'User',
    PROJECTS_PAGE: 'Your projects',
    COOKIE_DESCRIPTION: 'Cookies description',
    COOKIE_DURATION: 'Duration',
    COOKIE_POLICY: 'Privacy policy',
    USER_ROLE: 'Role',
    POWER_USER: 'Power user',
    NORMAL_USER: 'Normal user',
    USER_LOGIN: 'Login',
    USER_EMAIL: 'E-mail',
    SELECT: 'Select...',
    ACTIVE_USER: 'Active user',
    REQUIRED: 'Required',
    PROJECT_NAME: 'Project name',
    SELECT_ALL: 'Select all',
    STATUS_OK: 'OK',
    STATUS_BAD: 'Incorrect',
    STATUS_IMPROVEMENT: 'To Improve',
    STATUS_IRRELEVANT: 'Irrelevant',
    DEACTIVATE: 'Deactivate',
    ACTIVATE: 'Activate',
    NOT_IMPORTANT: 'Not important',
    TO_IMPROVE: 'To improve',
    WRONG: 'Wrong',
    OK: 'Ok',
    CATALOGUES: 'Catalogues'
  }
};
