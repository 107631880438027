import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import * as actions from '../actions';
import Projects from '../components/projects';

const mapStateToProps = state => {
  const {
    projects: { projects, projectsLoading },
    home: { role }
  } = state;

  return {
    role,
    projects,
    projectsLoading
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Projects);