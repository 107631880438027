import axios from 'axios';
import base64 from 'base-64';
import { toast } from 'react-toastify';
import { apiUrl } from '@src/config/api';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const setNewPassword = (pass, token) => {
  const clientId = 'test';
  const secret = 'secret';

  return dispatch => {
    dispatch({
      type: RESET_PASSWORD_REQUEST
    });

    // eslint-disable-next-line
    let bodyForm = new FormData();
    bodyForm.set('newPassword', pass);

    axios
      .post(`${ apiUrl() }/api/users/${ token }/password`, bodyForm, {
        headers: {
          Accept: 'application/json',
          Authorization: `Basic ${ base64.encode(`${ clientId }:${ secret }`) }`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(res => {
        dispatch({
          type: RESET_PASSWORD_SUCCESS
        });
      })
      .catch(e => {
        toast.error('An error occurred.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch({ type: RESET_PASSWORD_FAILED });
      });
  };
};