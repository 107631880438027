import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { Link } from 'react-router-dom';
import { checkRole } from '@src/utils/check-role';
import { Field, Checkbox } from '@src/components/shared';
import { required, max20 } from '@src/utils/validators';

class CatalogueBoxesForm extends Component {
  setEditMode = (cat, key) => {
    const {
      isCatalogueEditMode,
      editMode,
      selectedCatalogueId
    } = this.props;

    if ((editMode && !isCatalogueEditMode[key]) ||
      (selectedCatalogueId && selectedCatalogueId !== cat.id)) {
      return true;
    }
    return false;
  };
  render() {
    const {
      catalogues,
      currentUserRole,
      isCatalogueEditMode,
      editMode,
      editBtnAction,
      selectedCatalogue,
      selectedCatalogueId,
      currentUserId
    } = this.props;

    return (
      <Fragment>
        {map(catalogues, (cat, key) => (
          <div
            className={`
              col-xs-6 col-md-6 col-3
              catalogue
              ${ this.setEditMode(cat, key) ? 'catalogue-disable' : '' }
            `}
            key={key}
          >
            {(checkRole(currentUserRole) ||
              cat.authorId === currentUserId) &&
              <div className='catalogue__buttons-container'>
                {!isCatalogueEditMode[key] && !cat.main &&
                <Checkbox
                  name={`catalogue-${ cat.id }`}
                  disabled={this.setEditMode(cat, key)}
                  onChange={(event, checked) =>
                    selectedCatalogue(catalogues[key], event, checked)}
                />
                }
                <button
                  onClick={(event) => editBtnAction(key, event, cat)}
                  disabled={this.setEditMode(cat, key) || selectedCatalogueId === cat.id}
                >
                  {!editMode && !isCatalogueEditMode[key] ?
                    <i className='i i-pencil-1' />
                    :
                    <i className='i i-cancel' />
                  }
                </button>
                {editMode && isCatalogueEditMode[key] &&
                <button type='submit'>
                  <i className='i i-floppy-1' />
                </button>
                }
              </div>
            }
            <div className='thumbnail'>
              {!editMode && !isCatalogueEditMode[key] &&
              <Link
                to={`/projects/${ cat.projectId }/catalogue/${ cat.id }`}
                className={`${ this.setEditMode(cat, key) ? 'link-disabled' : ''}`}
              />
              }
              {cat.notificationCount > 0 && (
                <div className='cat__notification'>
                  <span>{cat.notificationCount}</span>
                </div>
              )}
              <span className='folder'>
                <span className='file' />
              </span>
              {!isCatalogueEditMode[key] ?
                <div className='title'>{cat.name}</div>
                :
                <Field
                  name={`catalogueName-${ cat.id }`}
                  type='text'
                  key={key}
                  validate={[required, max20]}
                />
              }
            </div>
          </div>
        ))}
      </Fragment>
    );
  }
}

CatalogueBoxesForm.defaultProps = {
  selectedCatalogueId: ''
};

CatalogueBoxesForm.propTypes = {
  catalogues: PropTypes.array.isRequired,
  currentUserRole: PropTypes.string.isRequired,
  isCatalogueEditMode: PropTypes.array.isRequired,
  editMode: PropTypes.bool.isRequired,
  editBtnAction: PropTypes.func.isRequired,
  selectedCatalogue: PropTypes.func.isRequired,
  selectedCatalogueId: PropTypes.string,
  currentUserId: PropTypes.string.isRequired
};

export default CatalogueBoxesForm;
