import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { Preloader, Table } from '@src/components/shared';
import { checkRole } from '@src/utils/check-role';
import moment from 'moment';

class Projects extends Component {
  state = {
    id: null
  };

  componentWillMount() {
    const { getProjects } = this.props;

    getProjects();
  }

  navigateTo = id => {
    this.setState({
      id
    });
  };

  render() {
    const {
      context: { t },
      state: { id },
      props: { projectsLoading, projects, role }
    } = this;

    if (id !== null) {
      return <Redirect to={`/projects/${ id }/catalogue`} />;
    }

    return (
      <div id='projects'>
        <div className='container'>
          <div className='row gutter'>
            <div className='col-12'>
              <h2 className='mb-8 mt-8'>
                {t('YOUR_PROJECTS')}
                {checkRole(role) && (
                  <Link className='btn blank f-right' to='/projects/create'>
                    Create new project
                  </Link>
                )}
              </h2>
            </div>
            {projectsLoading ? (
              <Preloader text='Loading projects...' />
            ) : (
              <Table
                data={orderBy(
                  projects,
                  [
                    o => {
                      return new moment(new Date(o.lastActionDate));
                    },
                    'name'
                  ],
                  ['desc', 'asc']
                )}
                datamap={[
                  'clientName',
                  'countryId',
                  'agencyName',
                  'projectName',
                  'date',
                  'notificationCount',
                  'id'
                ]}
                notSortable={['date', 'notificationCount', 'newInProject']}
                name='projects'
                header={['CLIENT', 'COUNTRY', 'AGENCY', 'PROJECT NAME', 'UPDATED', '', '']}
                tableEvent={this.navigateTo}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Projects.contextTypes = {
  t: PropTypes.func.isRequired
};
Projects.propTypes = {
  role: PropTypes.string.isRequired,
  projects: PropTypes.array.isRequired,
  projectsLoading: PropTypes.bool.isRequired,
  getProjects: PropTypes.func.isRequired
};

export default Projects;
