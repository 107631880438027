import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import * as actions from '../actions';

import Header from '../components/header';

const mapStateToProps = state => {
  const {
    download: { info, infoLoading  }
  } = state;

  return {
    info, infoLoading
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);