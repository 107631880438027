import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import CatalogueBoxesForm from './catalogue-boxes-form';

class CatalogueBoxes extends Component {
  submitCatalogueName = values => {
    const { updateCatalogueName, editedCatalogueId } = this.props;
    const fieldName = `catalogueName-${ editedCatalogueId }`;
    updateCatalogueName(editedCatalogueId, values[fieldName]);
  }
  render() {
    const {
      catalogues,
      handleSubmit,
      openPopup,
      currentUserRole,
      isCatalogueEditMode,
      editMode,
      editBtnAction,
      selectedCatalogue,
      selectedCatalogueId,
      pageWithSubCatalogues,
      parentCatalogueId,
      currentUserId
    } = this.props;

    return (
      <form onSubmit={handleSubmit(this.submitCatalogueName)}>
        <div className='row flex flex-middle'>
          <CatalogueBoxesForm
            catalogues={catalogues}
            currentUserRole={currentUserRole}
            isCatalogueEditMode={isCatalogueEditMode}
            selectedCatalogueId={selectedCatalogueId}
            editMode={editMode}
            editBtnAction={editBtnAction}
            selectedCatalogue={selectedCatalogue}
            pageWithSubCatalogues={pageWithSubCatalogues}
            currentUserId={currentUserId}
          />
          <div className={`
            col-xs-6 col-md-6 col-3 catalogue
            ${ editMode || selectedCatalogueId ? 'catalogue-disable' : '' }`}
          >
            <button
              className='thumbnail add-new'
              onClick={event => {
                event.preventDefault();
                openPopup('NewFolder', {
                  parentCatalogueId
                });
              }}
              disabled={editMode || selectedCatalogueId}
            >
              <i className='i i-plus' />
              <div className='title'>Add new folder</div>
            </button>
          </div>
        </div>
      </form>
    );
  }
}

CatalogueBoxes.defaultProps = {
  selectedCatalogueId: '',
  parentCatalogueId: ''
};

CatalogueBoxes.propTypes = {
  catalogues: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  updateCatalogueName: PropTypes.func.isRequired,
  editedCatalogueId: PropTypes.string.isRequired,
  currentUserRole: PropTypes.string.isRequired,
  isCatalogueEditMode: PropTypes.array.isRequired,
  editMode: PropTypes.bool.isRequired,
  editBtnAction: PropTypes.func.isRequired,
  selectedCatalogue: PropTypes.func.isRequired,
  selectedCatalogueId: PropTypes.string,
  pageWithSubCatalogues: PropTypes.bool.isRequired,
  parentCatalogueId: PropTypes.string,
  currentUserId: PropTypes.string.isRequired
};

const CatalogueBoxesWithForm = reduxForm({
  form: 'editCatalogue',
  enableReinitialize: true,
  onSubmitFail: () => {
    // errorScroll();
  }
})(CatalogueBoxes);

export default CatalogueBoxesWithForm;
