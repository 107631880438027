import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { Preloader, Table } from '@src/components/shared';

class Users extends Component {
  state = {
    id: null
  };

  componentWillMount() {
    const { getUsers } = this.props;

    getUsers();
  }

  navigateTo = id => {
    this.setState({
      id
    });
  };

  render() {
    const {
      context: { t },
      state: { id },
      props: { users, usersLoading }
    } = this;

    if (id !== null) {
      return <Redirect to={`/users/${ id }/settings`} />;
    }

    return (
      <div id='users'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='mb-8 mt-8'>
                {t('USERS_PAGE')}
                <Link className='btn blank f-right' to='/users/create'>
                  Create new user
                </Link>
              </h2>
            </div>
            {usersLoading ? (
              <Preloader text='Loading users...' />
            ) : (
              <Table
                data={users}
                datamap={['login', 'email', 'projectDTOs', 'userId']}
                notSortable={['projectDTOs']}
                specialData={['name']}
                name='users'
                header={['LOGIN', 'EMAIL', 'PROJECTS']}
                tableEvent={this.navigateTo}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Users.contextTypes = {
  t: PropTypes.func.isRequired
};
Users.propTypes = {
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  usersLoading: PropTypes.bool.isRequired
};

export default Users;