import {
  FILES_UPLOAD,
  FILES_UPLOAD_SUCCESS,
  FILES_UPLOAD_FAILED,
  FILES_UPLOAD_AFTER
} from './actions';

const INITIAL_STATE = {
  loaded: false,
  loading: false,
  error: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FILES_UPLOAD:
      return {
        ...state,
        loaded: false,
        loading: true
      };
    case FILES_UPLOAD_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false
      };
    case FILES_UPLOAD_FAILED:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: action.payload
      };
    case FILES_UPLOAD_AFTER:
      return {
        ...state,
        loaded: false
      };
    default:
      return state;
  }
}