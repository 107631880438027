/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

class AppRoute extends Component {
  componentDidMount() {
    const root = document.querySelector('#root');
    root.classList = this.getClassName();
  }

  componentDidUpdate(prevProps) {
    if (this.props.path !== prevProps.path) {
      this.onRouteChanged();
    }

    if (this.props.location !== prevProps.location) {
      const root = document.querySelector('#root');
      root.classList = this.getClassName();
    }
  }
  onRouteChanged = () => {
    const {
      routeChange,
      path,
      computedMatch: { params }
    } = this.props;

    const paramsValue =
      Object.keys(params).length === 0 && params.constructor === Object
        ? false
        : params;

    routeChange(path, paramsValue);
  };

  getClassName = () => {
    const { type } = this.props;
    const classList = type === 'main' ? 'home' : '';
    return classList;
  };

  render() {
    const { component: Scene, layout: Layout, ...routeProps } = this.props;

    return (
      <Route
        {...routeProps}
        render={matchProps => {
          if (Scene === undefined) {
            return null;
          }

          if (Layout === undefined) {
            return <Scene {...matchProps} />;
          }

          return (
            <Layout>
              <Scene {...matchProps} />
            </Layout>
          );
        }}
      />
    );
  }
}

export default AppRoute;
