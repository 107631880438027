import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import newId from '@src/utils/newid';

class TMRadioComponent extends Component {
  componentWillMount() {
    this.id = newId();
  }

  render() {
    const {
      input,
      meta: { touched, error },
      checkValue,
      mini,
      disabled,
      className
    } = this.props;

    return (
      <div className={touched && error ? 'error' : ''}>
        <div
          className={`tm-checkbox ${ className } ${
            disabled === true ? 'disabled' : ''
          } ${ mini ? 'mini' : '' } `}
        >
          <input
            {...input}
            type='radio'
            id={this.id}
            defaultChecked={checkValue !== undefined ? checkValue : input.value}
          />
          <label htmlFor={this.id}>
            <span />
            {this.props.text}
          </label>
        </div>
        {touched && error && <div>{error}</div>}
      </div>
    );
  }
}

TMRadioComponent.defaultProps = {
  disabled: false,
  mini: false,
  className: ''
};
TMRadioComponent.propTypes = {
  disabled: PropTypes.bool,
  mini: PropTypes.bool,
  className: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

const TMRadio = props => (
  <Field name={props.name} component={TMRadioComponent} {...props} />
);

TMRadio.propTypes = {
  name: PropTypes.string.isRequired
};

export default TMRadio;