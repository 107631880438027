import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Protected } from '@src/components/decorators';

@Protected(false)
export default class TermsPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Terms | STARS Gateway</title>
        </Helmet>
        <div id='terms'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>Terms Page</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}