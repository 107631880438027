import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import find from 'lodash/find';
import includes from 'lodash/includes';
import compact from 'lodash/compact';
import { Header as UserHeader } from '@src/components/users';
import { Preloader, CheckboxNew } from '@src/components/shared';
import { checkRole } from '@src/utils/check-role';

class UsersManage extends Component {
  componentWillMount() {
    const {
      getUser,
      match: { params }
    } = this.props;

    if (params && params.id) {
      getUser(params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      userUpdated,
      userUpdateActivitySuccess
    } = this.props;

    const {
      userUpdated: nextUserUpdated,
      userUpdateActivitySuccess: nextUserUpdateActivitySuccess
    } = nextProps;

    if ((!userUpdated && nextUserUpdated) || (!userUpdateActivitySuccess && nextUserUpdateActivitySuccess)) {
      this.props.history.push('/users');
    }
  }

  submitUserForm = data => {
    const {
      updateUser,
      projects,
      user,
      match: { params }
    } = this.props;

    if (params && params.id) {
      const userProjects = compact(
        map(data, (i, k) => {
          if (includes(k, 'project')) {
            const newId = k.split('_')[1];
            return {
              active: i,
              id: newId
            };
          }
          return false;
        })
      );
      const newProjects = compact(
        map(projects, n => {
          const disable = find(userProjects, o => {
            return o.id === n.id;
          });
          const activeNow = find(user.projects, o => {
            return o.id === n.id;
          });
          const active = disable ? disable.active : !!activeNow;
          if (!active) {
            return false;
          }
          return n.id;
        })
      );
      updateUser(newProjects, params.id);
    }
  };

  handleUpdateUserActivity = () => {
    const {
      user,
      updateUserActivity
    } = this.props;

    updateUserActivity(user.id);
  }

  render() {
    const {
      props: {
        handleSubmit,
        user,
        userLoading,
        projects,
        role: currentUserRole,
        openPopup
      },
      context: { t }
    } = this;

    return (
      <div id='users'>
        <div className='container'>
          {user && checkRole(currentUserRole) &&
            <div className='row flex flex-end'>
              <div className='col-12 text-right'>
                <button
                  className={`btn ${ user.active ? 'red' : 'primary' }`}
                  onClick={() => openPopup('ConfirmDeactivation', {
                    deactivateObjectName: 'user',
                    confirmDeactivateAction: this.handleUpdateUserActivity,
                    objectActiveStatus: user.active
                  })}
                >
                  {user.active ? t('DEACTIVATE') : t('ACTIVATE')}
                </button>
              </div>
            </div>
          }
          {!userLoading ? (
            <form
              onSubmit={handleSubmit(val => this.submitUserForm(val))}
              id='userForm'
            >
              <div className='row gutter'>
                <div className='col-12'>
                  <UserHeader user={user} />
                </div>
                <div className='col-12'>
                  <div className='row flex flex-middle mb-10'>
                    <div className='col-12'>
                      <h3 className='title'>User Active Projects</h3>
                    </div>
                    {map(projects, (i, k) => (
                      <div className='col-12 col-md-12 col-xs-12 mb-5' key={k}>
                        <CheckboxNew
                          name={`project_${ i.id }`}
                          text={i.name}
                          checkValue={includes(
                            map(user.projects, p => p.id),
                            i.id
                          )}
                        />
                      </div>
                    ))}
                  </div>
                  <div className='row flex flex-end'>
                    <div className='col-12'>
                      <button className='btn primary' type='submit'>
                        Save user
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <Preloader />
          )}
        </div>
      </div>
    );
  }
}

UsersManage.contextTypes = {
  t: PropTypes.func.isRequired
};

UsersManage.defaultProps = {
  user: {},
  role: ''
}

UsersManage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
  userLoading: PropTypes.bool.isRequired,
  userUpdated: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  updateUserActivity: PropTypes.func.isRequired,
  role: PropTypes.string,
  openPopup: PropTypes.func.isRequired,
  userUpdateActivitySuccess: PropTypes.bool.isRequired
};

const UsersManageWithForm = reduxForm({
  form: 'userForm',
  onSubmitFail: () => {
    // errorScroll();
  }
})(UsersManage);

export default UsersManageWithForm;
