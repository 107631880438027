import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Preloader } from '@src/components/shared';

class UploadFile extends Component {
  onDrop = files => {
    const {
      data: {
        catalogueId
      },
      uploadFiles
    } = this.props;

    for (let i = 0; i < files.length; i++) {
      uploadFiles(files[i], catalogueId, i === files.length - 1);
    }
  };

  render() {
    const { t } = this.context;
    const {
      loaded,
      loading,
      closePopup,
      changeState,
      data: {
        catalogueId
      }
    } = this.props;

    if (loaded) {
      closePopup();
      setTimeout(() => {
        changeState(catalogueId);
      }, 500);
    }

    return (
      <div id='UploadFile'>
        <h3 className='mb-8'>{t('UPLOAD_FILES')}</h3>
        {loading && <Preloader />}
        <Dropzone id='dropzone' multiple onDrop={this.onDrop}>
          <p>{t('DRAG_AND_DROP')}</p>
        </Dropzone>
      </div>
    );
  }
}

UploadFile.contextTypes = {
  t: PropTypes.func.isRequired
};

UploadFile.propTypes = {
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  changeState: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default withRouter(UploadFile);
