const regexes = {
  smallLetter: /[a-z]/,
  bigLetter: /[A-Z]/,
  digit: /[0-9]/,
  trans: /_/,
  email: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i, // eslint-disable-line no-control-regex
  phone: /(?:(\+)?)?(?:(\(?48\)?)[ -])?(\d{3}[ -]?\d{3}[ -]?\d{3})/
};

export const required = value => (value ? undefined : 'REQUIRED');

export const email = value =>
  value && !regexes.email.test(value) ? 'Incorrect e-mail address' : undefined;

export const phone = value =>
  value && !regexes.phone.test(value) ? 'Invalid phone number' : undefined;

export const max20 = value =>
  value && value.length >= 20
    ? 'Maximum limit of characters is 20.'
    : undefined;

export const toTranslate = value =>
  !!(value && regexes.trans.test(value) && !regexes.bigLetter.test(value));

export const isNumber = value => !Number.isNaN(parseFloat(value));

export const isBoolean = value => typeof value === 'boolean';

export const password = value => {
  if (value && value.length < 8) {
    return 'The password must contain at least 8 characters';
  }
  return undefined;
};


export const sizeVal = (size = 255) => value => (
  value && value.length > size
    ? `The text can not contain more than ${ size } chars` : undefined);
