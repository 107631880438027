import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import reduce from 'lodash/reduce';

// Actions
import { openPopup } from '@src/components/popup/actions';
import * as actions from '../actions';
import Catalogue from '../components/catalogue';
import { getDestinationCatalogue } from '../selectors';

const mapStateToProps = state => {
  const {
    download: {
      catalogues,
      cataloguesError,
      cataloguesLoading,
      projectDeactivateSuccess,
      updateCatalogueNameSuccess,
      parentCatalogues,
      updateCatalogueDestinationLoading,
      updateCatalogueDestinationError,
      pageWithSubCatalogues
    },
    projects: { projects },
    router: {
      params
    },
    home: {
      role,
      id: currentUserId
    }
  } = state;

  const project = params ? find(projects, elem => elem.id === params.id) : {};

  const cataloguesInitialName = reduce(catalogues, (result, value) =>
    ({ ...result, [`catalogueName-${ value.id }`]: value.name }), {});

  const destinationCatalogue = getDestinationCatalogue(state);

  const destinationCatalogueId = destinationCatalogue ? destinationCatalogue.value : '';

  return {
    catalogues,
    cataloguesError,
    cataloguesLoading,
    project,
    role,
    currentUserId,
    projectDeactivateSuccess,
    updateCatalogueNameSuccess,
    parentCatalogues,
    destinationCatalogueId,
    updateCatalogueDestinationLoading,
    updateCatalogueDestinationError,
    pageWithSubCatalogues,
    initialValues: {
      ...cataloguesInitialName
    }
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
      openPopup
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Catalogue)
);
