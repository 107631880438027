import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import newId from '@src/utils/newid';

class TMCheckboxComponent extends Component {
  componentWillMount() {
    this.id = newId();
  }

  render() {
    const {
      input,
      meta: { touched, error },
      checkValue
    } = this.props;

    return (
      <div className={touched && error ? 'error' : ''}>
        <div
          className={
            this.props.disabled === true
              ? 'tm-checkbox disabled'
              : 'tm-checkbox'
          }
        >
          <input
            {...input}
            type='checkbox'
            id={this.id}
            defaultChecked={checkValue !== undefined ? checkValue : input.value}
          />
          <label htmlFor={this.id}>
            <span />
            {this.props.text}
          </label>
        </div>
        {touched && error && <div>{error}</div>}
      </div>
    );
  }
}

TMCheckboxComponent.propTypes = {
  // text: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

const TMCheckbox = props => (
  <Field name={props.name} component={TMCheckboxComponent} {...props} />
);

TMCheckbox.propTypes = {
  name: PropTypes.string.isRequired
};

export default TMCheckbox;