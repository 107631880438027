import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actions from '../actions';
import Popup from '../components/popup';

const mapStateToProps = (state) => {
  const {
    popup: {
      data,
      openedPopup,
      pageType
    }
   } = state;

  return {
    data,
    openedPopup,
    pageType
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Popup);
