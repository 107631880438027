import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Home } from '@src/components/home';
import { Protected } from '@src/components/decorators';

@Protected(false)
class HomePage extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Home | STARS Gateway</title>
        </Helmet>
        <Home />
      </React.Fragment>
    );
  }
}

export default HomePage;