import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { common } from '@src/config';

class Error403 extends Component {
  render() {
    return (
      <div
        id='error'
        style={{
          background: `url('${ common.backgrounds[5] }')`
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='text-center animated delay-200ms flipInX'>
                403!
                <br />
                <small>You do not have rights to access this resource.</small>
              </h1>
              <p className='text-center mt-10  animated delay-400ms flipInX'>
                <Link className='gradient-button modal-button' to='/projects'>
                  Back to projects
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default Error403;
