import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import without from 'lodash/without';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import merge from 'lodash/merge';
import size from 'lodash/size';
import forEach from 'lodash/forEach';
import { Checkbox, Preloader, Radio, SelectAutocomplete } from '@src/components/shared';
import { checkRole } from '@src/utils/check-role';

class List extends Component {
  state = {
    list: null,
    listLength: null,
    listReady: false,
    selected: {
      download: [],
      upload: []
    },
    searchCatalogueQuery: ''
  };

  componentWillMount() {
    this.getYearsList();
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.formValues, nextProps.formValues)) {
      this.handleSelection(nextProps.formValues);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      searchCatalogueQuery,
      selected: {
        download,
        upload
      }
    } = this.state;

    const {
      searchCatalogueQuery: prevSearchCatalogueQuery,
      selected: {
        download: prevDownload,
        upload: prevUpload
      }
    } = prevState;


    if (
      (searchCatalogueQuery && searchCatalogueQuery !== prevSearchCatalogueQuery) ||
      (!searchCatalogueQuery && prevSearchCatalogueQuery) ||
      (download.length !== prevDownload.length) ||
      (upload.length !== prevUpload.length)
    ) {
      this.getPossibleParentCatalogues();
    }
  }

  getPossibleParentCatalogues = () => {
    const {
      getPossibleParentCatalogues,
      project: {
        id: projectId
      },
      activeCatalogue
    } = this.props;
    const {
      searchCatalogueQuery
    } = this.state;
    const data = {
      catalogueId: activeCatalogue.id,
      projectId,
      query: searchCatalogueQuery,
      fileDestination: true
    };
    getPossibleParentCatalogues(data);
  };

  getYearsList = () => {
    const { files, downloadType, userId } = this.props;

    const groupByYear = mapValues(
      groupBy(files, i => {
        return new Date(i.uploadTime).getFullYear();
      }),
      list =>
        without(
          list.map(i => {
            if (downloadType ? userId !== i.userId : userId === i.userId) {
              return i;
            }
          }),
          undefined
        )
    );
    let len = 0;
    forEach(groupByYear, i => (len += size(i)));

    this.setState({
      list: groupByYear,
      listLength: len,
      listReady: true
    });
  };

  removeThisFile = (id, reload = true) => {
    const {
      removeFile,
      match: {
        params: { catalogue: projectId }
      }
    } = this.props;

    removeFile(id, projectId, reload);
  };

  downloadSelected = array => {
    const { resetForm } = this.props;

    array.forEach(el => {
      this.downloadThisFile(el);
    });

    resetForm();
  };

  removeSelected = array => {
    array.forEach((el, k) => {
      this.removeThisFile(el, k === array.length - 1);
    });
  };

  moveToCatalogue = (destinationCatalogueId) => {
    const {
      activeCatalogue,
      moveFilesToCatalogue
    } = this.props;
    const { selected } = this.state;
    const selectedFiles = merge(selected.upload, selected.download);

    moveFilesToCatalogue(destinationCatalogueId, activeCatalogue.id, selectedFiles);
  };

  downloadThisFile = id => {
    const { files, downloadFile } = this.props;
    const filetoDownload = find(files, { id });
    const { originalFilename: name, fileType: type } = filetoDownload;

    downloadFile(id, name, type);
  };

  handleSelectAll = (select = true, type) => {
    const { changeAllCheckboxes } = this.props;
    const { list } = this.state;

    const mapNames = flattenDeep(
      map(list, e => map(e, ({ id }) => `${ type }[${ id }]`))
    );

    changeAllCheckboxes(select, mapNames);
    this.setState({
      selected: {
        ...this.state.selected,
        [type]: mapNames
      }
    });
  };

  handleSelection = formVal => {
    const selectedUpload = formVal
      ? without(
        map(formVal.upload, (v, k) => {
          if (v === true) {
            return k;
          }
          return false;
        }),
        false
      )
      : formVal;

    const selectedDownload = formVal
      ? without(
        map(formVal.download, (v, k) => {
          if (v === true) {
            return k;
          }
          return false;
        }),
        false
      )
      : formVal;

    this.setState({
      selected: {
        download: selectedDownload,
        upload: selectedUpload
      }
    });
  };

  handleFileStatus = (id, status) => {
    const { changeFileStatus } = this.props;

    changeFileStatus(id, status);
  };

  submitFilesForm = data => {
    console.log(data);
  };

  renderMoveFileComponent = (name, filesType) => {
    const {
      parentCatalogues,
      downloadFileDestinationCatalogue,
      uploadFileDestinationCatalogue,
      project
    } = this.props;

    const {
      selected: {
        download,
        upload
      }
    } = this.state;

    const destinationCatalogueId = () => {
      let catalogueId = '';
      if (filesType === 'upload' && uploadFileDestinationCatalogue) {
        catalogueId = uploadFileDestinationCatalogue.value;
      } else if (filesType === 'download' && downloadFileDestinationCatalogue) {
        catalogueId = downloadFileDestinationCatalogue.value;
      }
      return catalogueId;
    };

    return (
      <div className='row'>
        <div className='col-xs-8 col-md-6'>
          <SelectAutocomplete
            name={name}
            isDisabled={filesType === 'upload' ? upload.length === 0 : download.length === 0}
            onInputChange={value => this.setState({ searchCatalogueQuery: value })}
            options={parentCatalogues}
            project={project}
          />
        </div>
        <div className='col-xs-4 col-md-6'>
          <button
            className='btn'
            disabled={!destinationCatalogueId()}
            onClick={() => this.moveToCatalogue(destinationCatalogueId(), filesType === 'upload' ? 'upload' : 'download')}
          >
            <span className='title'>Move selected to catalogue</span>
          </button>
        </div>
      </div>
    );
  };

  // RENDER FUNCTIONS
  renderEmptyList = () => {
    const {
      context: { t },
      props: {
        downloadType,
        openPopup,
        activeCatalogue
      }
    } = this;

    return (
      <React.Fragment>
        <h2 className='mb-4 no files'>
          {downloadType ? t('FILES_TO_DOWNLOAD') : t('UPLOADED_FILES')}
        </h2>
        {!downloadType ? (
          <React.Fragment>
            <div className='row'>
              <div className='col-12'>
                <h2 className='mb-4 no__files'>{t('NO_FILES')}</h2>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 text-right'>
                <button
                  className='btn primary'
                  onClick={() => openPopup('UploadFile', {
                    catalogueId: activeCatalogue.id
                  })}
                >
                  {t('UPLOAD_NEW_FILE')}
                  <i className='i-upload' />
                </button>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className='row'>
            <div className='col-12'>
              <h2 className='mb-4 no__files'>{t('NO_FILES_TO_DOWNLOAD')}</h2>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  renderList = () => {
    const {
      context: { t },
      state: { list, selected },
      props: {
        downloadType,
        download,
        openPopup,
        home: { role },
        handleSubmit,
        activeCatalogue
      }
    } = this;

    return (
      <form onSubmit={handleSubmit(this.submitFilesForm)} id='filesForm'>
        <React.Fragment>
          <h2 className='mb-4'>
            {downloadType ? t('FILES_TO_DOWNLOAD') : t('UPLOADED_FILES')}
          </h2>
          <div className='row flex flex-middle mb-10'>
            <div className='col-12'>
              {map(list, (year, key) => {
                if (size(year) > 0) {
                  return (
                    <div className='col-12' key={key}>
                      <ul className='files__list'>
                        <li>
                          <div className='flex flex-middle'>
                            <div className='col-12 files__item'>
                              <Checkbox
                                name={`checkAll.${
                                  downloadType ? 'download' : 'upload'
                                }`}
                                onChange={({
                                             target: { name },
                                             currentTarget: { value }
                                           }) => {
                                  this.handleSelectAll(
                                    !(value !== '' && value === 'true'),
                                    name.split('.')[1]
                                  );
                                }}
                              />
                              <p>{t('SELECT_ALL')}</p>
                              <div />
                            </div>
                          </div>
                        </li>
                        {map(year, (item, key) => {
                          const split = item.originalFilename.split('.');
                          const fileType = split[split.length - 1];
                          const uploadTme = `${ new Date(
                            item.uploadTime
                          ).toLocaleDateString('en') } ${ new Date(
                            item.uploadTime
                          ).toLocaleTimeString('en') }`;

                          let fileName = item.originalFilename.replace(
                            `.${ fileType }`,
                            ''
                          );
                          if (fileName.length > 20) {
                            fileName = `${ fileName.substring(0, 20) }...`;
                          }

                          return (
                            <li key={key}>
                              <div className='flex flex-middle'>
                                <div className='col-10 col-xs-10 files__item'>
                                  <Checkbox
                                    name={`${
                                      downloadType ? 'download' : 'upload'
                                    }[${ item.id }]`}
                                  />
                                  <div className='tooltip__btn'>
                                    <p>{fileName}</p>
                                    <span className='tooltip__text'>{item.originalFilename}</span>
                                  </div>
                                  <span className={`tag ${ fileType }`}>
                                  .{fileType}
                                  </span>
                                  <div>{uploadTme}</div>
                                  {downloadType &&
                                  checkRole(role) &&
                                  item.uploadedByUser && (
                                    <div className='button_group'>
                                      {[
                                        {
                                          key: 0,
                                          label: t('STATUS_OK'),
                                          id: 'STATUS_OK'
                                        },
                                        {
                                          key: 1,
                                          label: t('STATUS_BAD'),
                                          id: 'STATUS_BAD'
                                        },
                                        {
                                          key: 2,
                                          label: t('STATUS_IMPROVEMENT'),
                                          id: 'STATUS_IMPROVEMENT'
                                        },
                                        {
                                          key: 3,
                                          label: t('STATUS_IRRELEVANT'),
                                          id: 'STATUS_IRRELEVANT'
                                        }
                                      ].map(i => (
                                        <Radio
                                          key={`status[${ item.id }][${ i.id }]`}
                                          text={i.label}
                                          checkValue={item.fileStatus === i.id}
                                          name={`status[${ item.id }].id`}
                                          className={i.id}
                                          mini
                                          onChange={() =>
                                            this.handleFileStatus(item.id, i.id)
                                          }
                                        />
                                      ))}
                                    </div>
                                  )}
                                  {!checkRole(role) && (
                                    <React.Fragment>
                                      {item.fileStatus !== null ? (
                                        <div
                                          className={`status ${ item.fileStatus }`}
                                        >
                                          {item.fileStatus === 'STATUS_OK' && (
                                            <span>
                                              <i className='i i-thumbs-up' />
                                              {t('OK')}
                                            </span>
                                          )}
                                          {item.fileStatus === 'STATUS_BAD' && (
                                            <span>
                                              <i className='i i-thumbs-up reverse' />
                                              {t('WRONG')}
                                            </span>
                                          )}
                                          {item.fileStatus ===
                                          'STATUS_IMPROVEMENT' && (
                                            <span>
                                              <i className='i i-pencil' />
                                              {t('TO_IMPROVE')}
                                            </span>
                                          )}
                                          {item.fileStatus ===
                                          'STATUS_IRRELEVANT' && (
                                            <span>
                                              <i className='i i-attach' />
                                              {t('NOT_IMPORTANT')}
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </React.Fragment>
                                  )}
                                </div>
                                <div className='col-2 col-xs-2 flex flex-end'>
                                  {downloadType ? (
                                    <button
                                      type='button'
                                      className='btn mini'
                                      onClick={() =>
                                        this.downloadThisFile(item.id)
                                      }
                                    >
                                      <span>{t('DOWNLOAD')}</span>
                                      <i className='i-download' />
                                    </button>
                                  ) : (
                                    <React.Fragment>
                                      <button
                                        type='button'
                                        className='btn mini onlyicon'
                                        onClick={() =>
                                          this.downloadThisFile(item.id)
                                        }
                                      >
                                        <i className='i-download' />
                                      </button>
                                      <button
                                        type='button'
                                        className='btn mini red'
                                        onClick={() =>
                                          this.removeThisFile(item.id)
                                        }
                                      >
                                        <span>{t('DELETE')}</span>
                                        <i className='i-trash' />
                                      </button>
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                }
              })}
            </div>
            {downloadType ? (
              <React.Fragment>
                <div className='col-12 mb-10'>
                  {download.catalogues.length > 1 && (
                    this.renderMoveFileComponent('download-files-destination-catalogues', 'download')
                  )}
                </div>
                <div className='col-12 text-right'>
                  <button
                    type='button'
                    className={`btn red ${
                      selected.download.length > 0 ? '' : 'disabled'
                    }`}
                    onClick={() => this.removeSelected(selected.download)}
                  >
                    {t('REMOVE_SELECTED')}
                    <i className='i-trash' />
                  </button>

                  <button
                    type='button'
                    className={`btn ${
                      selected.download.length > 0 ? '' : 'disabled'
                    }`}
                    onClick={() => this.downloadSelected(selected.download)}
                  >
                    {t('DOWNLOAD_SELECTED')}
                    <i className='i-download' />
                  </button>
                  {/* <button
                  type='button'
                  className='btn'
                  onClick={() => this.downloadAll()}
                >
                  {t('DOWNLOAD_ALL')}
                  <i className='i-download' />
                </button> */}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className='col-12 mb-10'>
                  {download.catalogues.length > 1 && (
                    this.renderMoveFileComponent('upload-files-destination-catalogues', 'upload')
                  )}
                </div>
                <div className='col-12 text-right'>
                  <button
                    type='button'
                    className={`btn red ${
                      selected.upload.length > 0 ? '' : 'disabled'
                    }`}
                    onClick={() => this.removeSelected(selected.upload)}
                  >
                    {t('REMOVE_SELECTED')}
                    <i className='i-trash' />
                  </button>
                  <button
                    type='button'
                    className='btn primary'
                    onClick={() => openPopup('UploadFile', {
                      catalogueId: activeCatalogue.id
                    })}
                  >
                    {t('UPLOAD_NEW_FILE')}
                    <i className='i-upload' />
                  </button>
                </div>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      </form>
    );
  };

  render() {
    const {
      listLength,
      listReady
    } = this.state;

    return (
      <React.Fragment>
        {!listReady ? (
          <Preloader text='Please wait...' />
        ) : (
          <React.Fragment>
            {listLength <= 0 ? this.renderEmptyList() : this.renderList()}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

List.contextTypes = {
  t: PropTypes.func.isRequired
};
List.defaultProps = {
  formValues: false,
  activeCatalogue: {},
  downloadFileDestinationCatalogue: null,
  uploadFileDestinationCatalogue: null
};

List.propTypes = {
  match: PropTypes.object.isRequired,
  removeFile: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  downloadType: PropTypes.bool.isRequired,
  downloadFile: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  openPopup: PropTypes.func.isRequired,
  moveFilesToCatalogue: PropTypes.func.isRequired,
  changeAllCheckboxes: PropTypes.func.isRequired,
  formValues: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  getPossibleParentCatalogues: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  activeCatalogue: PropTypes.object,
  download: PropTypes.object.isRequired,
  home: PropTypes.object.isRequired,
  parentCatalogues: PropTypes.array.isRequired,
  changeFileStatus: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  downloadFileDestinationCatalogue: PropTypes.object,
  uploadFileDestinationCatalogue: PropTypes.object
};

const ListWithForm = reduxForm({
  form: 'filesForm',
  onSubmitFail: () => {
    // errorScroll();
  }
})(List);

export default withRouter(ListWithForm);
