import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import moment from 'moment';

const fixProjects = col => {
  const newCol = map(col, i => ({
    id: i.id,
    active: i.active,
    agencyId: i.agencyId,
    agencyName: i.agencyName,
    clientId: i.clientId,
    clientName: i.clientName,
    countryId: i.countryId,
    countryName: i.countryName,
    notificationCount: i.notificationCount,
    newInProject: i.newInProject,
    lastActionDate:
      typeof i.lastActionDate !== typeof null ? i.lastActionDate : false,
    date:
      typeof i.lastActionDate !== typeof null
        ? moment(new Date(i.lastActionDate)).calendar()
        : false,
    name: `${ i.clientName } | ${ i.agencyName } | ${ i.countryId }`,
    projectName: i.name
  }));

  return orderBy(newCol, 'name', 'asc');
};

const fixUsers = col => {
  const newCol = map(col, i => ({
    userId: i.userId,
    email: i.email,
    login: i.login,
    projectDTOs: fixProjects(i.projectDTOs)
  }));
  return sortBy(newCol, 'login');
};

export default function (col, type) {
  switch (type) {
    case 'user':
      return fixUsers(col);
    case 'projects':
      return fixProjects(col);
    default:
      return false;
  }
}