import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import values from 'lodash/values';
import includes from 'lodash/includes';
import pick from 'lodash/pick';
import partialRight from 'lodash/partialRight';
import orderBy from 'lodash/orderBy';
import { isNumber, isBoolean } from '@src/utils/validators';
import NewIcon from '@res/images/new-icon.png';

class TableComponent extends Component {
  state = {
    sort: null,
    sortDesc: false
  };

  sortByKey = type => {
    this.setState(prevState => ({
      sort: type,
      sortDesc: prevState.sort === type ? !prevState.sortDesc : false
    }));
  };

  renderSpecialData = data => {
    const { specialData } = this.props;

    const pure = map(data, partialRight(pick, specialData));

    return (
      <React.Fragment>
        {map(pure, (item, key) => {
          const list = values(item, t => t);
          return (
            <div className='tag png' key={key}>
              {map(list, (d, k) => (
                <div key={k}>{d}</div>
              ))}
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  renderTbody = () => {
    const {
      props: {
        data, datamap, tableEvent, noSortable
      },
      state: { sort, sortDesc }
    } = this;

    const pureData = map(data, partialRight(pick, datamap));
    const dataToDisplay = sort
      ? orderBy(pureData, sort, sortDesc ? 'desc' : 'asc')
      : pureData;

    return (
      <React.Fragment>
        {map(dataToDisplay, (item, key) => (
          <div
            key={key}
            className='row'
            role='button'
            onClick={() => tableEvent(item.id ? item.id : item.userId)}
          >
            {map(item, (i, k) => {
              if (k === 'id' || k === 'userId') {
                return false;
              }
              return (
                <React.Fragment key={i}>
                  {!Array.isArray(i) ? (
                    <div>
                      {isNumber(i) && i !== null && i > 0 ? (
                        <div className='counter'>
                          <i className='i i-attach' />
                          <div className='tag mini pdf'>{i}</div>
                        </div>
                      ) : (
                        <React.Fragment>
                          {isBoolean(i) && i === true ? (
                            <img src={NewIcon} alt='New project' />
                          ) : (
                            <React.Fragment>{!isNumber(i) && i}</React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  ) : (
                    <div>{this.renderSpecialData(i)}</div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        ))}
      </React.Fragment>
    );
  };

  render() {
    const {
      context: { t },
      props: {
        name, datamap, header, notSortable
      },
      state: { sort, sortDesc }
    } = this;

    return (
      <div className={`table__${ name }`}>
        <div className='thead'>
          <div className='row flex-stretch'>
            {map(datamap, (i, key) => (
              <div
                key={key}
                role='button'
                onClick={() => {
                  if (i.length > 0 && !includes(notSortable, i)) {
                    this.sortByKey(i);
                  } else return false;
                }}
                className={`${
                  i.length > 0 && !includes(notSortable, i) ? 'sortable ' : ' '
                } ${ sort === i ? (sortDesc ? 'sort-desc' : 'sort-asc') : '' }`}
              >
                {header[key] !== '' ? t(header[key]) : ''}
              </div>
            ))}
          </div>
        </div>
        <div className='tbody'>{this.renderTbody()}</div>
      </div>
    );
  }
}

TableComponent.contextTypes = {
  t: PropTypes.func.isRequired
};
TableComponent.defaultProps = {
  specialData: [],
  notSortable: []
};
TableComponent.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
  datamap: PropTypes.array.isRequired,
  tableEvent: PropTypes.func.isRequired,
  notSortable: PropTypes.array,
  specialData: PropTypes.array
};
export default TableComponent;
