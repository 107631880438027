import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import * as actions from '../actions';

import Header from '../components/header';

const mapStateToProps = state => {
  const {
    users: { user, userLoading }
  } = state;

  return { user, userLoading };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);