import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink as Link } from 'react-router-dom';
import { common, Logo } from '@src/config';
import { checkRole } from '@src/utils/check-role';

class Header extends Component {
  render() {
    const { t } = this.context;
    const {
      logged,
      login,
      loginLoading,
      authenticationLogout,
      role,
      path
    } = this.props;

    return (
      <nav
        style={{
          background: `url('${ common.backgrounds[5] }')`
        }}
      >
        <div className='container'>
          <div className='row flex flex-middle'>
            <div className='col-4'>
              <Link to='/' className='animated delay-200ms fadeIn'>
                <Logo />
              </Link>
            </div>
            <div className='col-8 text-right'>
              <div className='row flex flex-end'>
                <div className='col-12'>
                  {!loginLoading && logged && (
                    <React.Fragment>
                      <div className='user__nav animated delay-200ms flipInX'>
                        <ul>
                          {checkRole(role) && (
                            <li>
                              <Link
                                to='/users'
                                className={path === '/users' ? 'active' : ''}
                              >
                                {t('USERS_PAGE')}
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link
                              to='/projects'
                              className={path === '/projects' ? 'active' : ''}
                            >
                              {t('PROJECTS_PAGE')}
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className='user__info animated delay-400ms flipInX'>
                        <p>{login}</p>
                        <button onClick={() => authenticationLogout()}>
                          {t('LOGOUT')}
                          <i className='i-exit' />
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

Header.contextTypes = {
  t: PropTypes.func.isRequired
};

export default Header;