import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import * as actions from '../reset-password-actions';

import PasswordReset from '../components/password-reset';

const mapStateToProps = state => {
  const {
    reset
  } = state;

  return {
    ...reset
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
