import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import I18n from 'redux-i18n';
import { createLogger } from 'redux-logger';
import { AppContainer } from 'react-hot-loader';
import reducers from '@src/reducer/index';
import App from '@src/app';
import { history, translations } from '@src/config';

if (!process.env.IS_BROWSER) {
  global.window = {};
}

/*
Here we are getting the initial state injected by the server. See routes/index.js for more details
 */
const initialState = typeof window !== "undefined" && window && window.__INITIAL_STATE__; // eslint-disable-line

const logger = createLogger({
  collapsed: true
});

const store = createStore(
  reducers,
  initialState,
  compose(
    applyMiddleware(thunk, logger)
  )
);
/*
While creating a store, we will inject the initial state we received from the server to our app.
 */
const render = (Component) => {
  let appCommon = {
    baseHref: '/'
  };
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <I18n translations={translations} initialLang='en'>
          <Router history={history}>
            <Component />
          </Router>
        </I18n>
      </Provider>
    </AppContainer>,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot) {
  module.hot.accept('@src/app', () => {
    // eslint-disable-next-line
    const nextApp = require('@src/app').default;
    render(nextApp);
  });
}
