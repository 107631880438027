import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import newId from '@src/utils/newid';
import { toTranslate } from '@src/utils/validators';

class TMFieldComponent extends Component {
  componentWillMount() {
    this.id = newId();
  }

  render() {
    const { t } = this.context;
    const {
      input,
      meta: { touched, error },
      text,
      className,
      ...rest
    } = this.props;
    let addonClass = '';
    if (input.value !== undefined && input.value !== '') {
      addonClass = 'filled';
    }
    return (
      <div className={touched && error ? 'error' : ''}>
        <div className={['tm-input', addonClass, className].join(' ')}>
          <input
            {...input}
            {...rest}
            id={this.id}
            autoComplete='off'
            autoCorrect='off'
            spellCheck='off'
          />
          <label htmlFor={this.id}>{this.props.text}</label>
          <span />
        </div>
        {touched && error && <div>{toTranslate(error) ? error : t(error)}</div>}
      </div>
    );
  }
}

TMFieldComponent.defaultProps = {
  className: ''
};

TMFieldComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

TMFieldComponent.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

const TMField = props => (
  <Field name={props.name} component={TMFieldComponent} {...props} />
);

TMField.propTypes = {
  name: PropTypes.string.isRequired
};

export default TMField;