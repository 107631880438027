import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

Footer.contextTypes = {
  t: PropTypes.func.isRequired
};

export default function Footer(props, context) {
  return (
    <footer>
      <div className='container'>
        <div className='row flex flex-middle animated delay-200ms slideInUp'>
          <div className='col-6'>
            <ul>
              <li>
                <Link to='/cookie-policy'>{context.t('COOKIES')}</Link>
              </li>
              <li>
                <Link to='/privacy-policy'>{context.t('PRIVACY')}</Link>
              </li>
            </ul>
          </div>
          <div className='col-6'>
            <p className='text-right'>
              {context.t('COPYRIGHT', { n: new Date().getFullYear() })}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
