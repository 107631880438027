import {
  FILES_REQUEST,
  FILES_SUCCESS,
  FILES_FAILED,
  INFO_REQUEST,
  INFO_SUCCESS,
  INFO_FAILED,
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILED,
  CATALOGUES_REQUEST,
  CATALOGUES_SUCCESS,
  CATALOGUES_FAILED,
  DEACTIVATE_PROJECT_REQUEST,
  DEACTIVATE_PROJECT_SUCCESS,
  DEACTIVATE_PROJECT_FAILED,
  UPDATE_CATALOGUE_NAME_REQUEST,
  UPDATE_CATALOGUE_NAME_SUCCESS,
  UPDATE_CATALOGUE_NAME_FAILED,
  SUB_CATALOGUES_REQUEST,
  SUB_CATALOGUES_SUCCESS,
  SUB_CATALOGUES_FAILED,
  GET_POSSIBLE_PARENT_CATALOGUES_REQUEST,
  GET_POSSIBLE_PARENT_CATALOGUES_SUCCESS,
  GET_POSSIBLE_PARENT_CATALOGUES_FAILED,
  UPDATE_CATALOGUE_DESTINATION_REQUEST,
  UPDATE_CATALOGUE_DESTINATION_SUCCESS,
  UPDATE_CATALOGUE_DESTINATION_FAILED,
  GET_ACTIVE_CATALOGUE_REQUEST,
  GET_ACTIVE_CATALOGUE_SUCCESS,
  GET_ACTIVE_CATALOGUE_FAILED
} from './actions';

const INITIAL_STATE = {
  files: [],
  filesLoading: false,
  filesError: null,
  catalogues: [],
  cataloguesLoading: false,
  cataloguesError: false,
  info: {
    agency: null,
    client: null,
    country: null
  },
  infoLoading: false,
  infoError: null,
  update: false,
  updated: false,
  projectDeactivateRequest: false,
  projectDeactivateSuccess: false,
  projectDeactivateError: null,
  updateCatalogueNameRequest: false,
  updateCatalogueNameSuccess: false,
  updateCatalogueNameError: null,
  subCatalogues: [],
  subCataloguesLoading: false,
  subCataloguesError: false,
  pageWithSubCatalogues: false,
  parentCatalogues: [],
  parentCataloguesLoading: false,
  parentCataloguesError: false,
  updateCatalogueDestinationLoading: false,
  updateCatalogueDestinationSuccess: false,
  updateCatalogueDestinationError: false,
  activeCatalogueLoading: false,
  activeCatalogue: null,
  activeCatalogueError: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CATALOGUES_REQUEST:
      return {
        ...state,
        cataloguesLoading: true,
        pageWithSubCatalogues: false
      };
    case CATALOGUES_SUCCESS:
      return {
        ...state,
        catalogues: action.payload,
        cataloguesLoading: false,
        activeCatalogue: null
      };
    case CATALOGUES_FAILED:
      return {
        ...state,
        cataloguesLoading: false,
        cataloguesError: true
      };
    case UPDATE_STATUS_REQUEST:
      return {
        ...state,
        update: true,
        updated: false
      };
    case UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        update: false,
        updated: true
      };
    case UPDATE_STATUS_FAILED:
      return {
        ...state,
        update: false,
        updated: false
      };
    case INFO_REQUEST:
      return {
        ...state,
        infoLoading: true
      };
    case INFO_SUCCESS:
      return {
        ...state,
        info: action.payload,
        infoLoading: false
      };
    case INFO_FAILED:
      return {
        ...state,
        infoError: action.payload,
        infoLoading: false
      };

    case FILES_REQUEST:
      return {
        ...state,
        filesLoading: true
      };
    case FILES_SUCCESS:
      return {
        ...state,
        files: action.payload,
        filesLoading: false
      };
    case FILES_FAILED:
      return {
        ...state,
        filesError: action.payload,
        filesLoading: false
      };
    case DEACTIVATE_PROJECT_REQUEST:
      return {
        ...state,
        projectDeactivateRequest: true,
        projectDeactivateSuccess: false,
        projectDeactivateError: null
      };
    case DEACTIVATE_PROJECT_SUCCESS:
      return {
        ...state,
        projectDeactivateRequest: false,
        projectDeactivateSuccess: true,
        projectDeactivateError: null
      };
    case DEACTIVATE_PROJECT_FAILED:
      return {
        ...state,
        projectDeactivateRequest: false,
        projectDeactivateSuccess: false,
        projectDeactivateError: action.payload
      };
    case UPDATE_CATALOGUE_NAME_REQUEST:
      return {
        ...state,
        updateCatalogueNameRequest: true,
        updateCatalogueNameSuccess: false,
        updateCatalogueNameError: null
      };
    case UPDATE_CATALOGUE_NAME_SUCCESS:
      return {
        ...state,
        updateCatalogueNameRequest: false,
        updateCatalogueNameSuccess: true,
        updateCatalogueNameError: null
      };
    case UPDATE_CATALOGUE_NAME_FAILED:
      return {
        ...state,
        updateCatalogueNameRequest: false,
        updateCatalogueNameSuccess: false,
        updateCatalogueNameError: action.payload
      };
    case SUB_CATALOGUES_REQUEST:
      return {
        ...state,
        subCataloguesLoading: true,
        pageWithSubCatalogues: true
      };
    case SUB_CATALOGUES_SUCCESS:
      return {
        ...state,
        subCatalogues: action.payload,
        subCataloguesLoading: false
      };
    case SUB_CATALOGUES_FAILED:
      return {
        ...state,
        subCataloguesLoading: false,
        subCataloguesError: true
      };
    case GET_POSSIBLE_PARENT_CATALOGUES_REQUEST:
      return {
        ...state,
        parentCataloguesLoading: true
      };
    case GET_POSSIBLE_PARENT_CATALOGUES_SUCCESS:
      return {
        ...state,
        parentCatalogues: action.payload,
        parentCataloguesLoading: false
      };
    case GET_POSSIBLE_PARENT_CATALOGUES_FAILED:
      return {
        ...state,
        parentCataloguesLoading: false,
        parentCataloguesError: true
      };

    case UPDATE_CATALOGUE_DESTINATION_REQUEST:
      return {
        ...state,
        updateCatalogueDestinationLoading: true,
        updateCatalogueDestinationError: false
      };
    case UPDATE_CATALOGUE_DESTINATION_SUCCESS:
      return {
        ...state,
        updateCatalogueDestinationSuccess: true,
        updateCatalogueDestinationLoading: false,
        updateCatalogueDestinationError: false
      };
    case UPDATE_CATALOGUE_DESTINATION_FAILED:
      return {
        ...state,
        updateCatalogueDestinationLoading: false,
        updateCatalogueDestinationError: true
      };

    case GET_ACTIVE_CATALOGUE_REQUEST:
      return {
        ...state,
        activeCatalogueLoading: true,
        activeCatalogue: null,
        activeCatalogueError: false
      };
    case GET_ACTIVE_CATALOGUE_SUCCESS:
      return {
        ...state,
        activeCatalogueLoading: false,
        activeCatalogue: action.payload,
        activeCatalogueError: false
      };
    case GET_ACTIVE_CATALOGUE_FAILED:
      return {
        ...state,
        activeCatalogueLoading: false,
        activeCatalogueError: true
      };
    default:
      return state;
  }
}
