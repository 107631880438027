import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Confirm extends Component {
  remove = () => {
    const {
      routeParams,
      history,
      removeCatalogue,
      data: {
        activeCatalogue
      }
    } = this.props;

    removeCatalogue(activeCatalogue.id, routeParams.id, history);
    // history.goBack();
    // closePopup();
  };

  render() {
    const {
      closePopup,
      data: {
        activeCatalogue
      }
    } = this.props;

    return (
      <div id='WelcomePopup'>
        <h3 className='mb-4'>Remove catalogue: <i>{activeCatalogue.name}</i></h3>
        <p className='mb-10'>
          Are you sure? All the sub-catalogues and the files inside that catalogue will be also
          removed.
        </p>
        <div className='row'>
          <div className='col-12 text-right'>
            <button
              className='btn blank'
              onClick={() => {
                closePopup();
              }}
            >
              Cancel
            </button>
            <button
              className='btn primary'
              onClick={() => {
                this.remove();
              }}
            >
              Yes, remove
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Confirm.defaultProps = {
  data: null
};

Confirm.propTypes = {
  routeParams: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  closePopup: PropTypes.func.isRequired,
  removeCatalogue: PropTypes.func.isRequired,
  data: PropTypes.object
};
export default Confirm;
