import fixCollection from '@src/utils/fix-collection';
import {
  GET_PROJECT_REQUEST,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAILED,
  CREATE_PROJECT_REQ,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILED,
  GET_OPTIONS_REQUEST,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAILED
} from './actions';

const INITIAL_STATE = {
  projects: [],
  projectsLoading: false,
  projectsError: false,
  created: false,
  options: null,
  optionsLoading: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PROJECT_REQUEST:
      return {
        ...state,
        projectsLoading: true
      };
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        projects: fixCollection(action.payload, 'projects'),
        projectsLoading: false
      };
    case GET_PROJECT_FAILED:
      return {
        ...state,
        projectsError: true,
        projectsLoading: false
      };
    case CREATE_PROJECT_REQ:
      return {
        ...state,
        created: false
      };
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        created: true
      };
    case CREATE_PROJECT_FAILED:
      return {
        ...state,
        created: true
      };
    case GET_OPTIONS_REQUEST:
      return {
        ...state,
        optionsLoading: true
      };
    case GET_OPTIONS_SUCCESS:
      return {
        ...state,
        options: action.payload,
        optionsLoading: false
      };
    case GET_OPTIONS_FAILED:
      return {
        ...state,
        options: null,
        optionsLoading: false
      };
    default:
      return state;
  }
}