export const ROUTE_CHANGE = 'ROUTE_CHANGE';
export const APP_INITIALIZE = 'APP_INITIALIZE';

export function routeChange(payload, params = false) {
  return {
    type: ROUTE_CHANGE,
    payload, params
  };
}

export function appInitialize(payload) {
  return {
    type: APP_INITIALIZE,
    payload,
  };
}
