import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { closePopup } from '@src/components/popup/actions';
import { saveCatalogue } from '@src/components/download';
import * as actions from '../actions';

import NewFolder from '../components/new-folder';

const mapStateToProps = state => {
  const {
    upload,
    router: { params: routerParams },
    popup: { data }
  } = state;

  return {
    ...upload,
    routerParams,
    data
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
      closePopup,
      saveCatalogue
    },
    dispatch
  );
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NewFolder)
);
