import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { Preloader } from '@src/components/shared';
import { Header as DownloadHeader } from '@src/components/download';
import { checkRole } from '@src/utils/check-role';
import { CatalogueBoxes } from './shared/catalogue-boxes';
import { MoveCatalogue } from './shared/move-catalogue';

class Catalogue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      isCatalogueEditMode: [],
      editedCatalogueId: '',
      selectedCatalogueId: '',
      searchCatalogueQuery: ''
    };

    this.baseState = this.state;
  }

  componentWillMount() {
    this.getMarketAllCatalogues();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      projectDeactivateSuccess,
      history,
      catalogues,
      updateCatalogueNameSuccess,
      updateCatalogueDestinationError,
      updateCatalogueDestinationLoading
    } = this.props;
    const {
      projectDeactivateSuccess: prevProjectDeactivateSuccess,
      catalogues: prevCatalogues,
      updateCatalogueNameSuccess: prevUpdateCatalogueNameSuccess,
      updateCatalogueDestinationLoading: prevUpdateCatalogueDestinationLoading
    } = prevProps;

    const {
      searchCatalogueQuery,
      selectedCatalogueId
    } = this.state;
    const {
      searchCatalogueQuery: prevSearchCatalogueQuery,
      selectedCatalogueId: prevSelectedCatalogueId
    } = prevState;

    if (projectDeactivateSuccess && !prevProjectDeactivateSuccess) {
      history.push('/projects');
    }

    if (catalogues.length > 0 && prevCatalogues.length === 0) {
      this.setInitialCatalogueEditMode(catalogues.map(() => false));
    }

    if (!prevUpdateCatalogueNameSuccess && updateCatalogueNameSuccess) {
      this.getMarketAllCatalogues();
      this.setEditModeState();
      this.setInitialCatalogueEditMode(catalogues.map(() => false));
    }

    if (!updateCatalogueDestinationError && !updateCatalogueDestinationLoading && prevUpdateCatalogueDestinationLoading) {
      this.getMarketAllCatalogues();
      this.resetState();
    }

    if (
      (searchCatalogueQuery && searchCatalogueQuery !== prevSearchCatalogueQuery) ||
      (!searchCatalogueQuery && prevSearchCatalogueQuery) ||
      (selectedCatalogueId && !prevSelectedCatalogueId)
    ) {
      this.possibleParentCatalogues();
    }
  }

  setInitialCatalogueEditMode = stateValue => {
    this.setState({ isCatalogueEditMode: stateValue });
  };

  getMarketAllCatalogues = () => {
    const {
      match: { params },
      getCatalogues
    } = this.props;

    getCatalogues(params.id);
  };

  setCataloguesEditMode = (index, event) => {
    const {
      isCatalogueEditMode
    } = this.state;
    event.preventDefault();
    const editedValuesArray = isCatalogueEditMode.map(item => item);

    editedValuesArray[index] = !isCatalogueEditMode[index] || false;

    this.setState({
      isCatalogueEditMode: editedValuesArray
    });
  };

  setEditModeState = () => this.setState({ editMode: !this.state.editMode });

  setSelectedCatalogueId = (catalogue, checked) => {
    let selectedCatalogueId = '';

    if (checked) {
      selectedCatalogueId = catalogue.id;
    } else {
      selectedCatalogueId = '';
    }
    this.setState({
      selectedCatalogueId
    });

    return selectedCatalogueId;
  };

  getSearchCataloguesValue = () => {
    const { parentCatalogues } = this.props;

    const destinationCatalogues = map(parentCatalogues, cat => ({
      label: cat.name,
      value: cat.catalogueId
    }));

    return destinationCatalogues;
  };

  handleDeactivateProject = () => {
    const {
      project,
      deactivateProject
    } = this.props;

    deactivateProject(project.id);
  };

  possibleParentCatalogues = () => {
    const {
      getPossibleParentCatalogues,
      project: {
        id: projectId
      }
    } = this.props;
    const {
      selectedCatalogueId,
      searchCatalogueQuery
    } = this.state;
    const data = {
      catalogueId: selectedCatalogueId,
      projectId,
      query: searchCatalogueQuery
    };
    getPossibleParentCatalogues(data);
  };

  updateCatalogueDestination = () => {
    const {
      destinationCatalogueId,
      updateCatalogueDestination
    } = this.props;

    const { selectedCatalogueId } = this.state;

    updateCatalogueDestination(selectedCatalogueId, destinationCatalogueId);
  };

  resetState = () => this.setState(this.baseState);

  render() {
    const {
      catalogues,
      cataloguesLoading,
      openPopup,
      project,
      role: currentUserRole,
      currentUserId,
      updateCatalogueName,
      destinationCatalogueId,
      updateCatalogueDestinationLoading,
      pageWithSubCatalogues
    } = this.props;

    const {
      isCatalogueEditMode,
      editMode,
      editedCatalogueId,
      selectedCatalogueId
    } = this.state;

    const { t } = this.context;
    return (
      <div id='download'>
        <div className='container'>
          <div className='row gutter'>
            {checkRole(currentUserRole) &&
            <div className='col-12 text-right'>
              <button
                className='btn red'
                onClick={() => openPopup('ConfirmDeactivation', {
                  deactivateObjectName: 'project',
                  confirmDeactivateAction: this.handleDeactivateProject,
                  objectActiveStatus: project.active
                })}
              >
                {t('DEACTIVATE')}
              </button>
            </div>
            }
            <div className='col-12'>
              <DownloadHeader />
            </div>
            <div className='col-12'>
              {cataloguesLoading ? (
                <Preloader text='Please wait. The catalogues are loading...' />
              ) : (
                <Fragment>
                  <CatalogueBoxes
                    openPopup={openPopup}
                    catalogues={catalogues}
                    currentUserRole={currentUserRole}
                    currentUserId={currentUserId}
                    isCatalogueEditMode={isCatalogueEditMode}
                    editMode={editMode}
                    pageWithSubCatalogues={pageWithSubCatalogues}
                    updateCatalogueName={updateCatalogueName}
                    editedCatalogueId={editedCatalogueId}
                    selectedCatalogueId={selectedCatalogueId}
                    selectedCatalogue={(catalogue, event, checked) => {
                      this.setSelectedCatalogueId(catalogue, checked);
                    }}
                    editBtnAction={(key, event, cat) => {
                      this.setCataloguesEditMode(key, event);
                      this.setEditModeState();
                      this.setState({ editedCatalogueId: cat.id });
                    }}
                  />
                  <MoveCatalogue
                    btnTitle='Move catalogue'
                    options={this.getSearchCataloguesValue()}
                    isDisabled={!selectedCatalogueId}
                    disabledSubmitBtn={!destinationCatalogueId}
                    onInputChange={value => this.setState({ searchCatalogueQuery: value })}
                    submitDestination={() => this.updateCatalogueDestination()}
                  />
                  {updateCatalogueDestinationLoading &&
                    <Preloader text='Please wait. The catalogue is moved.' />
                  }
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Catalogue.contextTypes = {
  t: PropTypes.func.isRequired
};

Catalogue.defaultProps = {
  role: ''
};

Catalogue.propTypes = {
  match: PropTypes.object.isRequired,
  getCatalogues: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  catalogues: PropTypes.array.isRequired,
  cataloguesLoading: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  deactivateProject: PropTypes.func.isRequired,
  role: PropTypes.string,
  projectDeactivateSuccess: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  updateCatalogueName: PropTypes.func.isRequired,
  updateCatalogueNameSuccess: PropTypes.bool.isRequired,
  getPossibleParentCatalogues: PropTypes.func.isRequired,
  parentCatalogues: PropTypes.array.isRequired,
  updateCatalogueDestination: PropTypes.func.isRequired,
  destinationCatalogueId: PropTypes.string.isRequired,
  updateCatalogueDestinationLoading: PropTypes.bool.isRequired,
  updateCatalogueDestinationError: PropTypes.bool.isRequired,
  pageWithSubCatalogues: PropTypes.bool.isRequired,
  currentUserId: PropTypes.string.isRequired
};

export default Catalogue;
