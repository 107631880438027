import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MoveCatalogue from '../components/move-catalogue';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveCatalogue);
