import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from '@src/components/shared';
import { required } from '@src/utils/validators';

class ForgotPassword extends Component {
  render() {
    const { t } = this.context;
    const {
      submitResetPassword,
      cancelResetPassword
    } = this.props;

    return (
      <div className='row animated flipInX'>
        <div className='col-6'>
          <Field
            name='user'
            type='text'
            text={t('USERNAME')}
            validate={[required]}
          />
        </div>
        <div className='col-6'>
          <button
            type='button'
            className='btn login mt-4'
            onClick={() => submitResetPassword()}
          >
            {t('RESET_PASSWORD')}?
          </button>
        </div>
        <div className='col-12'>
          <button
            type='button'
            className='btn link mt-2'
            onClick={() => cancelResetPassword()}
          >
            {t('CANCEL')}
          </button>
        </div>
      </div>
    );
  }
}

ForgotPassword.contextTypes = {
  t: PropTypes.func.isRequired
};

ForgotPassword.propTypes = {
  submitResetPassword: PropTypes.func.isRequired,
  cancelResetPassword: PropTypes.func.isRequired
}

export default ForgotPassword;
