import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import * as actions from '../actions';
import NewUser from '../components/new-user';

const mapStateToProps = state => {
  const {
    users: { created },
    projects: { projects }
  } = state;

  return { created, projects };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewUser));