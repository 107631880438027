import axios from 'axios';
import { toast } from 'react-toastify';
import { fRrefreshToken, clearUserData } from '@src/components/authorization/actions';
// const ENABLE_CREDENTIALS = process.env.NODE_ENV === 'development' ? true : false;

export const apiUrl = () => {
  return process.env.NODE_ENV === 'development'
    ? 'https://gateway-stage.silk-sh.eu'
    : window.location.origin;
};

export const getOptions = async (options, useAccessToken = true) => {
  const cOptions = options || {};
  let accessToken = localStorage.getItem('access_token');

  if (!Object.prototype.hasOwnProperty.call(cOptions, 'headers')) {
    cOptions.headers = {};
    cOptions.headers['Content-Type'] = 'application/json';
  }

  if (accessToken !== null && useAccessToken) {
    const expireTime = JSON.parse(localStorage.getItem('expire_time'));
    const currentTime = new Date().getTime();

    if (currentTime > expireTime) {
      await fRrefreshToken();
    }

    accessToken = await localStorage.getItem('access_token');
    cOptions.headers.Authorization = `Bearer ${ accessToken }`;
  }

  return cOptions;
};

export default async (url, options) => {
  const cOptions = await getOptions(options);

  return axios({
    ...cOptions,
    url,
    baseURL: `${ apiUrl() }/api`
    // withCredentials: !!ENABLE_CREDENTIALS
  })
    .then(response => {
      if (response.status === 401 || response.status === 400) {
        clearUserData();
        return false;
      } else if (response.status === 201) {
        return response;
      }

      return response.data;
    })
    .catch(e => {
      if (e.response.status === 401) {
        clearUserData();
        window.location = '/login';
        return false;
      }
      if (e.response.status === 403) {
        window.location = '/error-403';
        return false;
      }
      if (e.response && e.response.data.message) {
        toast.error(e.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    });
};
