import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { setLanguage } from 'redux-i18n';
import { common } from '@src/config';

class Language extends React.Component {
  componentDidMount() {
    var defaultLanguage = localStorage.getItem('setLanguage') !== null ? localStorage.getItem('setLanguage') : 'en';
    this.props.dispatch(setLanguage(defaultLanguage))
  }

  onChangeLang(lang) {
    this.props.dispatch(setLanguage(lang.slug))
    localStorage.setItem('setLanguage', lang.slug);
  }


  render() {
    const {
      lang
    } = this.props;
    return (
      <ul className='switch__lang'>
        { common.languages.map(lang => (
          <li key={lang.slug} className={ this.props.lang === lang.slug ? 'active' : '' }>
            <button onClick={() => this.onChangeLang(lang)}>
              { lang.slug }
            </button>
          </li>
        )) }
      </ul>
    );
  }
}

Language.contextTypes = {
  t: PropTypes.func.isRequired
}

export default connect(state => ({
  lang: state.i18nState.lang
}))(Language)
