export const TOGGLE_POPUP = 'TOGGLE_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';


export function openPopup(pageType, data) {
  return dispatch => [
    dispatch({ type: TOGGLE_POPUP,
      pageType: pageType !== false ? pageType : null,
      openedPopup: true,
      data: typeof data !== typeof undefined ? data : null
    })
  ];
}

export function closePopup() {
  return dispatch => [
    dispatch({
      type: CLOSE_POPUP,
      openedPopup: false
    })
  ];
}