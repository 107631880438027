import {
  CHANGE_LEVEL,
} from './actions';

const INITIAL_STATE = {
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_LEVEL:
      return {
        ...state
      };
    default:
      return state;
  }
}

