import React, { Component } from 'react';

class Popup extends Component {
  componentWillUpdate() {
    this.handleRootElement();
  }
  componentDidUpdate() {
    this.handleRootElement();
  }

  handleRootElement = () => {
    const { openedPopup } = this.props;

    const root = document.querySelector('html');
    if (openedPopup === true) root.classList.add('modal-active');
    else root.classList.remove('modal-active');
  };

  handleOpenPopup = () => {
    const { closePopup } = this.props;
    closePopup();
  };

  renderSpecificPopup = item => {
    const { pageType } = this.props;
    if (pageType === item.props.id) return item;
  };

  render() {
    const {
      openedPopup, pageType, children: content, ...rest
    } = this.props;

    return openedPopup ? (
      <div className='modal modal-center active'>
        <div className='modal-content'>
          <button className='close-button' onClick={this.handleOpenPopup}>
            <i className='i-plus' />
          </button>
          {pageType && content.map(this.renderSpecificPopup.bind(this))}
        </div>
      </div>
    ) : null;
  }
}

export default Popup;