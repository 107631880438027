import React, { Component } from 'react';
import { Header, Footer } from '@src/components/header';
import Favicon from 'react-favicon';
import favicona from '@res/images/favicon/favicon.ico';

class Layout extends Component {
  render() {
    const { children: scene } = this.props;

    return [
      <Favicon url={favicona} />,
      <Header key='layout_header' />,
      <main key='layout_main'>{scene}</main>,
      <Footer key='layout_footer' />
    ];
  }
}

export default Layout;