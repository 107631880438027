import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { submit } from 'redux-form';
import PropTypes from 'prop-types';

class Header extends Component {
  render() {
    const { t } = this.context;
    const { user, userLoading } = this.props;

    return (
      <div className='files__header'>
        <div>
          <Link to='/users' className='btn link'>
            <i className='i i-left-open ml-0' />
            Users
          </Link>
        </div>
        {!userLoading && user !== null && (
          <React.Fragment>
            {user.login && (
              <div>
                <span>{t('USER_LOGIN')}</span>
                <p>{user.login}</p>
              </div>
            )}
            {user.email && (
              <div>
                <span>{t('USER_EMAIL')}</span>
                <p>{user.email}</p>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

Header.contextTypes = {
  t: PropTypes.func.isRequired
};
Header.propTypes = {
  user: PropTypes.object.isRequired,
  userLoading: PropTypes.bool.isRequired
};

export default Header;