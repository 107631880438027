import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Users } from '@src/components/users';
import { Protected } from '@src/components/decorators';

@Protected(true)
class UsersPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Users | STARS Gateway</title>
        </Helmet>
        <Users />
      </React.Fragment>
    );
  }
}

export default UsersPage;