import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Download } from '@src/components/download';
import { Protected } from '@src/components/decorators';

@Protected(true)
class DownloadPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Download Files | STARS Gateway</title>
        </Helmet>
        <Download />
      </React.Fragment>
    );
  }
}

export default DownloadPage;
