import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { map, find } from 'lodash';

// Actions
import { openPopup } from '@src/components/popup/actions';
import * as actions from '../actions';

import { getDestinationCatalogue } from '../selectors';
import Download from '../components/download';

const mapStateToProps = state => {
  const {
    upload: { loading: uploading, loaded: uploaded },
    projects: { projects },
    download,
    home,
    router: {
      params
    }
  } = state;

  let mapFiels = {};

  map(download.files[0], (item) => {
    mapFiels = {
      ...mapFiels,
      [item.id]: true
    };
  });

  const project = params ? find(projects, elem => elem.id === params.id) : {};

  const destinationCatalogue = getDestinationCatalogue(state);
  const destinationCatalogueId = destinationCatalogue ? destinationCatalogue.value : '';

  return {
    download,
    home,
    uploading,
    uploaded,
    initialValues: { download: mapFiels },
    destinationCatalogueId,
    project
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
      openPopup
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Download)
);
