import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import * as actions from '../actions';
import NewProject from '../components/new-project';

const mapStateToProps = state => {
  const {
    projects: { created, options, optionsLoading }
  } = state;

  return { created, options, optionsLoading };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewProject));