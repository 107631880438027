import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import newId from '@src/utils/newid';

class TMSelectComponent extends Component {
  componentWillMount() {
    this.id = newId();
  }

  render() {
    const {
      input, meta, className, disabled
    } = this.props;
    let addonClass = '';
    if (input.value !== undefined && input.value !== '') {
      addonClass = 'filled';
    }

    return (
      <div className={meta.touched && meta.error ? 'error' : ''}>
        <div
          className={[
            `tm-input ${ disabled ? 'disabled' : '' }`,
            addonClass,
            className
          ].join(' ')}
        >
          <select id={this.id} {...input}>
            <option value='' disabled hidden />
            {this.props.data.map(({ label, id }) => (
              <option key={id} value={id}>
                {label}
              </option>
            ))}
          </select>
          <label htmlFor={this.id}>{this.props.text}</label>
          <span />
        </div>
        {meta.touched && meta.error && <div>{meta.error}</div>}
      </div>
    );
  }
}

TMSelectComponent.defaultProps = {
  disabled: false
};

TMSelectComponent.propTypes = {
  text: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

const TMSelect = props => (
  <Field name={props.name} component={TMSelectComponent} {...props} />
);

TMSelect.propTypes = {
  data: PropTypes.array.isRequired
};

export default TMSelect;