import {
  AUTHENTICATION_REQUEST,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAILED,
  AUTHENTICATION_CHECK,
  AUTHENTICATION_LOGOUT
} from './actions';

const INITIAL_STATE = {
  logged: null,
  loginLoading: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTHENTICATION_REQUEST:
      return {
        ...state,
        logged: false,
        loginLoading: true
      };

    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        logged: true,
        loginLoading: false,
        ...action.payload
      };

    case AUTHENTICATION_FAILED:
      return {
        ...state,
        logged: false,
        loginLoading: false
      };

    case AUTHENTICATION_CHECK:
      return {
        ...state,
        ...action.payload,
        loginLoading: false
      };

    case AUTHENTICATION_LOGOUT:
      return {
        logged: false,
        loginLoading: false
      };

    default:
      return state;
  }
}
