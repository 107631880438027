import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { UsersManage } from '@src/components/users';
import { Protected } from '@src/components/decorators';

@Protected(true)
class UsersManagePage extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>User Settings | STARS Gateway</title>
        </Helmet>
        <UsersManage />
      </React.Fragment>
    );
  }
}

export default UsersManagePage;