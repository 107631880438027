import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import * as actions from '../actions';

import Users from '../components/users';

const mapStateToProps = state => {
  const {
    users: { usersLoading, users }
  } = state;

  return {
    usersLoading,
    users
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);