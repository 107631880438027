import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import compact from 'lodash/compact';
import includes from 'lodash/includes';
import { required, email, sizeVal } from '@src/utils/validators';
import { Field, CheckboxNew } from '@src/components/shared';

class NewUser extends Component {
  componentWillReceiveProps(nextProps) {
    if (!this.props.created && nextProps.created) {
      this.props.history.push('/users');
    }
  }

  submitNewUserForm = data => {
    const { projects, createUser } = this.props;
    const userProjects = map(data, (i, k) => {
      if (includes(k, 'project') && i === true) {
        return projects[k.split('_')[1]].id;
      }
      return '';
    });

    const parsedData = {
      active: typeof data.active !== typeof undefined ? data.active : true,
      email: typeof data.email !== typeof undefined ? data.email : '',
      login: typeof data.login !== typeof undefined ? data.login : '',
      projectIds: compact(userProjects)
    };
    createUser(parsedData);
  };

  sizeVal = sizeVal();

  render() {
    const {
      context: { t },
      props: { handleSubmit, projects }
    } = this;

    return (
      <div id='users'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='mb-8 mt-8'>Create new user</h2>
            </div>
            <div className='col-12'>
              <form
                onSubmit={handleSubmit(this.submitNewUserForm)}
                id='newUserForm'
              >
                <div className='row gutter'>
                  <div className='col-12'>
                    <div className='row flex flex-top mb-15'>
                      <div className='col-12'>
                        <h3 className='title'>User Base Settings</h3>
                      </div>
                      <div className='col-4 col-md-6 col-xs-12'>
                        <Field
                          type='text'
                          name='email'
                          text='E-mail'
                          className='big'
                          validate={[required, email, this.sizeVal]}
                        />
                      </div>
                      <div className='col-4 col-md-6 col-xs-12'>
                        <Field
                          type='text'
                          name='login'
                          text='Login'
                          className='big'
                          validate={[required, this.sizeVal]}
                        />
                      </div>
                    </div>
                    <div className='row flex flex-middle mb-10'>
                      <div className='col-12'>
                        <h3 className='title'>User Active Projects</h3>
                      </div>
                      {map(projects, (i, k) => (
                        <div className='col-6 col-md-6 col-xs-12 mb-5' key={k}>
                          <CheckboxNew
                            name={`project_${ k }`}
                            text={`${ i.clientName } | ${ i.agencyName } | ${
                              i.countryId
                            }`}
                          />
                        </div>
                      ))}
                    </div>
                    <div className='row flex flex-end'>
                      <div className='col-12'>
                        <button className='btn primary' type='submit'>
                          Save new user
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NewUser.contextTypes = {
  t: PropTypes.func.isRequired
};
NewUser.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  created: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired
};

const NewUserWithForm = reduxForm({
  form: 'newUserForm',
  onSubmitFail: () => {
    // errorScroll();
  }
})(NewUser);

export default NewUserWithForm;
