import { combineReducers } from 'redux';
import { i18nState } from 'redux-i18n';
import { reducer as form } from 'redux-form';

// App reducers
import { reducer as router } from '@src/router';
import { reducer as header } from '@src/components/header';
import { reducer as download } from '@src/components/download';
import { reducer as popup } from '@src/components/popup';
import { reducer as upload } from '@src/components/popup/components/upload';
import { reducer as projects } from '@src/components/projects';
import { reducer as users } from '@src/components/users';
import {
  reducer as home,
  resetPasswordReducer as reset
} from '@src/components/authorization';

const rootReducer = combineReducers({
  router,
  form,
  header,
  home,
  download,
  popup,
  upload,
  reset,
  projects,
  users,
  i18nState
});

export default rootReducer;
