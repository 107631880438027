import api from '@src/config/api';
import { toast } from 'react-toastify';

// GET USERS
export const GET_USERS_REQ = 'GET_USERS_REQ';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

// CREATE USER
export const CREATE_USER_REQ = 'CREATE_USER_REQ';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

// GET USER
export const GET_USER_ID_REQ = 'GET_USER_ID_REQ';
export const GET_USER_ID_SUCCESS = 'GET_USER_ID_SUCCESS';
export const GET_USER_ID_FAILED = 'GET_USER_ID_FAILED';

// UPDATE USER
export const UPDATE_USER_ID_REQ = 'UPDATE_USER_ID_REQ';
export const UPDATE_USER_ID_SUCCESS = 'UPDATE_USER_ID_SUCCESS';
export const UPDATE_USER_ID_FAILED = 'UPDATE_USER_ID_FAILED';

// GET USER FILES
export const GET_USER_FILES_REQ = 'GET_USER_FILES_REQ';
export const GET_USER_FILES_SUCCESS = 'GET_USER_FILES_SUCCESS';
export const GET_USER_FILES_FAILED = 'GET_USER_FILES_FAILED';

export const UPDATE_USER_ACTIVITY_REQUEST = 'UPDATE_USER_ACTIVITY_REQUEST';
export const UPDATE_USER_ACTIVITY_SUCCESS = 'UPDATE_USER_ACTIVITY_SUCCESS';
export const UPDATE_USER_ACTIVITY_FAILED = 'UPDATE_USER_ACTIVITY_FAILED';

export function getUsers() {
  return dispatch => {
    dispatch({ type: GET_USERS_REQ });

    api('/users')
      .then(res => {
        dispatch({
          type: GET_USERS_SUCCESS,
          payload: res
        });
      })
      .catch(() => {
        dispatch({ type: GET_USERS_FAILED });
      });
  };
}

export function getUser(id) {
  return dispatch => {
    dispatch({ type: GET_USER_ID_REQ });

    api(`/users/${ id }`)
      .then(res => {
        dispatch({
          type: GET_USER_ID_SUCCESS,
          payload: res
        });
      })
      .catch(() => {
        dispatch({ type: GET_USER_ID_FAILED });
      });
  };
}

export function createUser(data) {
  return dispatch => {
    dispatch({ type: CREATE_USER_REQ });

    // eslint-disable-next-line
    let formData = new FormData();
    // eslint-disable-next-line
    for (let key in data) {
      formData.append(key, data[key]);
    }

    return api('/users', {
      method: 'POST',
      data
    })
      .then(res => {
        dispatch({
          type: CREATE_USER_SUCCESS,
          payload: res
        });
        toast.success('The user have been successfully saved', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(() => {
        dispatch({ type: CREATE_USER_FAILED });
      });
  };
}

export function updateUser(data, userId) {
  return dispatch => {
    dispatch({ type: UPDATE_USER_ID_REQ });
    api(`/users/${ userId }/projects`, {
      method: 'PUT',
      data
    })
      // TODO: after request
      .then(res => {
        dispatch({
          type: UPDATE_USER_ID_SUCCESS,
          payload: res
        });
        toast.success(
          'The projects have been successfully added for this user,',
          {
            position: toast.POSITION.BOTTOM_RIGHT
          }
        );
      })
      .catch(() => {
        dispatch({ type: UPDATE_USER_ID_FAILED });
      });
  };
}

export const updateUserActivity = (userId) => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_USER_ACTIVITY_REQUEST'
    });
    api(`/users/${ userId }/activity`, {
      method: 'PUT'
    })
      .then(() => {
        dispatch({
          type: 'UPDATE_USER_ACTIVITY_SUCCESS'
        });
        toast.success(
          'The user activity status have been successfully updated',
          {
            position: toast.POSITION.BOTTOM_RIGHT
          }
        );
      })
      .catch(() => {
        toast.error('An error occurred.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      });
  };
};
