import api from '@src/config/api';
import { toast } from 'react-toastify';
import { getFiles } from '@src/components/download';

export const FILES_UPLOAD = 'FILES_UPLOAD';
export const FILES_UPLOAD_SUCCESS = 'FILES_UPLOAD_SUCCESS';
export const FILES_UPLOAD_FAILED = 'FILES_UPLOAD_FAILED';
export const FILES_UPLOAD_AFTER = 'FILES_UPLOAD_AFTER';

export const changeState = id => {
  return dispatch => {
    dispatch({
      type: FILES_UPLOAD_AFTER
    });
    dispatch(getFiles(id));
  };
};

export const uploadFiles = (file, catalogueId, reload = true) => {
  return dispatch => {
    dispatch({
      type: FILES_UPLOAD
    });
    const data = new FormData();
    data.append('file', file);

    return api(`catalogues/${ catalogueId }/files`, {
      method: 'POST',
      data
    })
      .then(res => {
        dispatch({
          type: FILES_UPLOAD_SUCCESS
        });
        if (reload) {
          toast.success('The files has been successfully uploaded.', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        }
      })
      .catch(e => {
        dispatch({
          type: FILES_UPLOAD_FAILED
        });
        toast.error('An error occurred while trying to upload the files.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      });
  };
};