import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { closePopup } from '@src/components/popup/actions';
import * as actions from '../actions';

import Upload from '../components/upload';

const mapStateToProps = (state) => {
  const {
    upload,
    router: { params: routerParams },
    popup: { data }
  } = state;

  return {
    ...upload, routerParams, data
  };
};


const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions, closePopup
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Upload);
