import React, { Component } from 'react';

class Welcome extends Component {
  render() {
    return (
    <div id="WelcomePopup">
      Welcome
    </div>
    );
  }
}

export default Welcome;
