// import { combineReducers } from 'redux';
// import { reducer as extensionReducer } from './components/extension';
// import { reducer as paymentReducer } from './components/payment';

import {
  TOGGLE_POPUP,
  CLOSE_POPUP
} from './actions';

const INITIAL_STATE = {
  openedPopup: false,
  pageType: null,
  data: null
};

export default function (state = INITIAL_STATE, action) {
// const popupReducer = function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_POPUP:
      return {
        ...state,
        pageType: action.pageType,
        openedPopup: action.openedPopup,
        data: action.data
      };
    case CLOSE_POPUP:
      return {
        ...state,
        openedPopup: action.openedPopup,
        data: null,
      };
    default:
      return state;
  }
}

// export default combineReducers({
//   extensionReducer,
//   popupReducer,
//   paymentReducer,
// });
