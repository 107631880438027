import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import newId from '@src/utils/newid';

class TMCheckboxComponent extends Component {
  componentWillMount() {
    this.id = newId();
  }

  render() {
    const {
      input,
      meta: { touched, error },
      ...rest
    } = this.props;

    return (
      <div className={touched && error ? 'error' : ''}>
        <div
          className={
            this.props.disabled === true
              ? 'tm-input checkbox disabled'
              : 'tm-input checkbox'
          }
        >
          <input
            {...input}
            type='checkbox'
            id={this.id}
            checked={this.props.input.value}
            {...rest}
          />
          <label htmlFor={this.id}>
            <div dangerouslySetInnerHTML={{ __html: this.props.text }} />
          </label>
        </div>
        {touched && error && <div>{error}</div>}
      </div>
    );
  }
}

TMCheckboxComponent.propTypes = {
  // text: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

const TMCheckbox = props => (
  <Field name={props.name} component={TMCheckboxComponent} {...props} />
);

TMCheckbox.propTypes = {
  name: PropTypes.string.isRequired
};

export default TMCheckbox;
