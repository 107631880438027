import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { openPopup } from '@src/components/popup/actions';
import * as actions from '../actions';
import UsersManage from '../components/users-manage';

const mapStateToProps = state => {
  const {
    users: {
      user,
      userLoading,
      userUpdated,
      userUpdateActivitySuccess
    },
    projects: { projects, projectsLoading },
    home: {
      role
    }
  } = state;

  return {
    user,
    userLoading,
    userUpdated,
    projects,
    projectsLoading,
    role,
    userUpdateActivitySuccess
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
      openPopup
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UsersManage)
);
